import React from "react";
import { useState, useEffect } from "react";

interface CountdownProp {
  startDate: string;
}
const calculateTimeLeft = (startDate: string) => {
  const difference = +new Date(startDate) - +new Date();

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
    };
  } else {
    return null;
  }
};
const Countdown: React.FC<CountdownProp> = ({ startDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startDate));

  useEffect(() => {
    let animationFrameId: number;

    const updateCountdown = () => {
      setTimeLeft(calculateTimeLeft(startDate));
      animationFrameId = requestAnimationFrame(updateCountdown);
    };

    updateCountdown();

    return () => cancelAnimationFrame(animationFrameId);
  }, [startDate]);

  return (
    <div>
      {timeLeft ? (
        <span>
          {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m
        </span>
      ) : (
        <span>Event Started!</span>
      )}
    </div>
  );
};

export default Countdown;
