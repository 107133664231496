import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

interface PurchaseSummaryProps {
  subtotal: number;
  taxesAndFees: number;
}

const PurchaseSummary: React.FC<PurchaseSummaryProps> = ({ subtotal, taxesAndFees }) => {
  const total = subtotal + taxesAndFees;

  return (
    <Box sx={{ padding:'2em', borderRadius:'1em', height: '100%', width: '100%', bgcolor: 'background.paper', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom component="div">
        PURCHASE SUMMARY
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ flexGrow: 1 }}>
        Price can change depending on taxes of your state.
      </Typography>
      <Divider />
      <Box display="flex" justifyContent="space-between" my={1}>
        <Typography variant="body2">Subtotal</Typography>
        <Typography variant="body2">{`$${subtotal.toFixed(2)}`}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" my={1}>
        <Typography variant="body2">Taxes & fees</Typography>
        <Typography variant="body2">{`$${taxesAndFees.toFixed(2)}`}</Typography>
      </Box>
      <Divider sx={{ my: 1, width: '80%', mx: 'auto' }} />
      <Box display="flex" justifyContent="space-between" my={1}>
        <Typography variant="body2">Total purchase</Typography>
        <Typography variant="body2">{`$${total.toFixed(2)}`}</Typography>
      </Box>
    </Box>
  );
};

export default PurchaseSummary;
