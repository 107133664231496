import React from 'react';
import { Box, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

interface User {
  firstName: string;
  picture: string;
  id: string;
}

interface Team {
  name: string;
  imageUrl: string;
  id: string;
}

interface TournamentInfoProps {
  tournamentData: {
    users?: User[];
    teams?: Team[];
    teamSize: string;
  };
}

const ParticipantsList: React.FC<TournamentInfoProps> = ({tournamentData}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedParticipant, setSelectedParticipant] = React.useState<string | null>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, participantId: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedParticipant(participantId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedParticipant(null);
  };

  const handleViewProfile = (participantId: string, isTeam: boolean) => {
    if (isTeam) {
      navigate(`/team-dashboard/${participantId}`);
    } else {
      navigate(`/profile/${participantId}`);
    }
    handleClose();
  };

  return (
    <Box sx={{ width: '80%', marginX: 'auto', bgcolor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2rem' }}>
      <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 2 , textAlign: 'center'}}>
        CONFIRMED
      </Typography>
      <List sx={{ width: '100%', bgcolor: 'transparent' }}> 
        {tournamentData.teamSize === '1v1' ? (
          tournamentData.users?.map((user) => (
            <ListItem
              key={user.id}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #e0e0e0', width: '100%' }}
            >
              <ListItemAvatar>
                <Avatar alt={user.firstName} src={user.picture} />
              </ListItemAvatar>
              <ListItemText primary={user.firstName} />
              <IconButton onClick={(event) => handleClick(event, user.id)}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`participant-menu-${user.id}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl && selectedParticipant === user.id)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Share</MenuItem>
                <MenuItem onClick={() => handleViewProfile(user.id, false)}>View Profile</MenuItem>
              </Menu>
            </ListItem>
          ))
        ) : (
          tournamentData.teams?.map((team) => (
            <ListItem
              key={team.id}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #e0e0e0', width: '100%' }}
            >
              <ListItemAvatar>
                <Avatar alt={team.name} src={team.imageUrl} />
              </ListItemAvatar>
              <ListItemText primary={team.name}/>
              <IconButton onClick={(event) => handleClick(event, team.id)}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`participant-menu-${team.id}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl && selectedParticipant === team.id)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Share</MenuItem>
                <MenuItem onClick={() => handleViewProfile(team.id, true)}>View Team</MenuItem>
              </Menu>
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
};

export default ParticipantsList;
