import React, { useEffect, useRef, useState } from "react";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useAuth } from "react-oidc-context";
import { Button, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ClaimMatchEndResult } from "../services/wagerService";
import { useSnackbar } from "../components/SnackbarProvider";
import { useAppSelector } from "../hooks";
import { addMessage, clearMessage, messageType } from "../reducers/chatMessage";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { uploadProofToWager } from "../utils/AwsUtils";
import Typography from "@mui/material/Typography";

const WaitingRoom = () => {
  const location = useLocation();
  const { wager, user } = location.state || {};

  console.log(wager, user);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const chatroomName = !!wager ? wager : "sample";

  const userName = !!user ? user : "";
  const auth = useAuth();
  const token = auth.user?.id_token;

  const connection = useAppSelector((state) => state.connection.value);

  const dispatch = useDispatch();
  const isConnEstablised = useRef<boolean>(false);
  const joinChatRoom = async (userName: string, chatRoom: string) => {
    try {
      console.log("joining chatRoom");
      if (!connection) return;
      console.log(connection);

      await connection.start();
      await setConnectionChannels(userName, chatRoom);
    } catch (e) {
      console.log(e);
    }
  };

  const setConnectionChannels = async (userName: string, chatRoom: string) => {
    if (!connection || isConnEstablised.current) return;
    try {
      isConnEstablised.current = true;
      connection!.off("JoinChatSpecificChat");
      connection!.off("ReceivedSpecificMessage");
      connection.on("JoinChatSpecificChat", (userName: string, msg: string) => {
        console.log("msg: ", msg);
        dispatch(addMessage({ userName, msg }));
      });

      await connection.invoke("JoinChatSpecificChat", {
        userName,
        chatRoom,
      });

      connection.on("ReceivedSpecificMessage", (userName, msg) => {
        console.log("received message");
        dispatch(addMessage({ userName, msg }));
      });
    } catch (e) {
      console.log(e);
    }
  };

  const ClaimResult = async (result: string) => {
    await ClaimMatchEndResult(wager, result, auth.user?.id_token).then(
      (onValue) => {
        showSnackbar("Your Claim has been successfully recorded", "success");
        // setTimeout(() => {
        //   // Your code to execute after 3 seconds
        //   navigate("/wager");
        // }, 3000); // 3000 milliseconds = 3 seconds
      },
    );
  };

  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    console.log("join chatroom", wager, user);
    dispatch(clearMessage());
    console.log(connection!.state);
    if (connection!.state === HubConnectionState.Disconnected)
      joinChatRoom(userName, chatroomName);
    else if (connection!.state === HubConnectionState.Connected) {
      setConnectionChannels(userName, chatroomName);
    }

    return () => {
      // connection!.off("JoinChatSpecificChat");
      // connection!.off("ReceivedSpecificMessage");
    };
  }, []);

  const MessageContainer = () => {
    const chatList = useAppSelector((state) => state.message.value);

    return (
      <div>
        {chatList.map((message, index) => (
          <div key={index}>
            <span>{message.userName}: </span>
            <span>{message.msg}</span>
          </div>
        ))}
      </div>
    );
  };

  const ChatRoom = () => {
    const [message, setMessage] = useState<string>("");
    const sendMessage = (message: string) => {
      connection!.invoke("SendMessage", message);
      console.log(message);
    };

    return (
      <div>
        <h2>Chat Room</h2>
        <MessageContainer />
        <div>
          <input
            type="text"
            placeholder="Type your message..."
            onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={() => sendMessage(message)}>Send</button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Stack direction="row" spacing={10}>
        <ChatRoom />
        <Stack spacing={6}>
          <Button
            variant="contained"
            color={"success"}
            sx={{
              display: "inline-block",
              px: 4,
              py: 2,
            }}
            onClick={async () => {
              try {
                await ClaimResult("Win");
              } catch (e) {
                console.log(e);
              }
            }}
          >
            I win
          </Button>
          <Button
            variant="contained"
            color={"error"}
            sx={{
              display: "inline-block",
              px: 4,
              py: 2,
            }}
            onClick={async () => {
              try {
                await ClaimResult("Lose");
              } catch (e) {
                console.log(e);
              }
            }}
          >
            I Lose
          </Button>
          <Button
            variant="contained"
            color={"info"}
            sx={{
              display: "inline-block",
              px: 4,
              py: 2,
            }}
            onClick={async () => {
              try {
                await ClaimResult("Draw");
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Match Draw
          </Button>
          <Box>
            <Typography variant={"h6"}>
              Proof of result in case of conflict
            </Typography>
            <input type="file" onChange={handleFileChange} />
            <Button
              variant={"contained"}
              onClick={async () => {
                try {
                  if (file != null && token != null)
                    await uploadProofToWager(file, token, wager).then(() => {
                      showSnackbar(
                        "File has successfully submitted!",
                        "success",
                      );
                    });
                  else
                    showSnackbar(
                      "File must not be empty and user needs to be authenticated!",
                      "error",
                    );
                } catch (e) {
                  showSnackbar(`${e}`, "error");
                }
              }}
            >
              Upload
            </Button>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default WaitingRoom;

interface MessageContainerProps {
  messages: messageType[];
  connection: HubConnection;
}
