import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container, Grid, Stack, TextField } from "@mui/material";
import Banner from "../components/ProfileBanner";
import Achievements from "../components/AchievementsBar";
import ProfileEarningsComponent from "../components/ProfileEarningsComponent";
import ProfileStatisticsComponent from "../components/ProfileStatisticsComponent";
import { useParams } from "react-router-dom";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import ActiveTourney from "../components/ActiveTourney";
import ProfileTeam from "../components/ProfileTeam";
import { fetchUserBalance, setGamerTags } from "../services/userService";
import { RequestPayment } from "../services/paymentService";
import { useSnackbar } from "../components/SnackbarProvider";
import { useDispatch } from "react-redux";
import { decrement, increment } from "../reducers/counterSlicer";
import { useAppSelector } from "../hooks";
import { BASE_URL_API } from "../services/fetchData";
import { fetchWagerHistory } from "../services/wagerService";
import WagerHistory from "../components/WagerHistory";

export type UserData = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
  isOnline: boolean;
  playstationGamertag: string | null;
  xboxGamertag: string | null;
  steamGamertag: string | null;
  riotGamesGamertag: string | null;
  activisionGamertag: string | null;
  isVerified: boolean;
  profileViews: number;
  goldTrophy: number;
  silverTrophy: number;
  bronzeTrophy: number;
  wagerMoneyEarned: number;
  tournamentMoneyEarned: number;
  totalMoneyEarned: number;
  totalTournamentsWon: number;
  totalGamesPlayed: number;
  totalTournamentPlayed: number;
  totalWagersPlayed: number;
};

export type WagerHistory = {
  gameName: string;
  type: string;
  gamerTagPlayerA: string;
  gamerTagPlayerB: string;
  prizePool: number;
  result: string;
  createdAt: string;
};

const Profile = () => {
  const auth = useAuth();
  const { id } = useParams<{ id: string }>();
  const [participant, setParticipant] = useState<UserData | null>(null);
  const [tournaments, setTournaments] = useState<any[]>([]);
  const [shouldPoll, setShouldPoll] = useState<boolean>(true);
  const { showSnackbar } = useSnackbar();
  const userSlicer = useAppSelector((state) => state.user.value);
  useEffect(() => {
    const fetchParticipant = async () => {
      try {
        const idToken = auth.user?.id_token;
        if (idToken) {
          const response = await fetch(`${BASE_URL_API}/User/${id}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log("Fetched participant data:", data);
          setParticipant(data);

          if (data.email === auth.user?.profile.email) {
            setShouldPoll(false);
          }
        }
      } catch (error) {
        console.error("Error fetching participant data:", error);
      }
    };

    const fetchTournaments = async () => {
      try {
        const idToken = auth.user?.id_token;
        if (idToken) {
          const response = await fetch(
            `${BASE_URL_API}/User/tournaments/${id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            },
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log("Fetched tournament data:", data);
          setTournaments(data);
        }
      } catch (error) {
        console.error("Error fetching tournament data:", error);
      }
    };

    fetchParticipant();
    fetchTournaments();

    let interval: NodeJS.Timeout;
    if (shouldPoll) {
      interval = setInterval(() => {
        fetchParticipant();
        fetchTournaments();
      }, 15000); // 15 secs polling
    }

    return () => clearInterval(interval);
  }, [id, auth.user, shouldPoll]);

  const [balance, setBalance] = useState<number>(0);
  const [history, setHistory] = useState<WagerHistory[]>([]);

  useEffect(() => {
    fetchUserBalance(auth.user?.id_token).then((id) => setBalance(id));
    fetchWagerHistory(auth.user?.id_token).then((items) => setHistory(items));
  }, []);

  const Counter = () => {
    const count = useAppSelector((state) => state.counter.value);
    const dispatch = useDispatch();

    return (
      <Box bgcolor={"white"}>
        <div>
          <button
            aria-label="Increment value"
            onClick={() => dispatch(increment())}
          >
            Increment
          </button>
          <span>{count}</span>
          <button
            aria-label="Decrement value"
            onClick={() => dispatch(decrement())}
          >
            Decrement
          </button>
        </div>
      </Box>
    );
  };

  const BalanceCard = () => {
    const [payOutAmount, setPayoutAmount] = useState(0);
    return (
      <Box bgcolor={"white"}>
        <Typography bgcolor={"white"} variant={"h4"}>
          Total Balance: {balance}
        </Typography>{" "}
        <Typography bgcolor={"white"} variant={"h4"}>
          Cash Out
        </Typography>
        {/*<TextField*/}
        {/*  fullWidth*/}
        {/*  InputLabelProps={{*/}
        {/*    style: { color: "#000" },*/}
        {/*  }}*/}
        {/*  label={"paypal email"}*/}
        {/*></TextField>*/}
        <TextField
          fullWidth
          variant="filled"
          InputLabelProps={{
            style: { color: "#000" },
          }}
          color={"secondary"}
          label={"CAD"}
          type={"number"}
          onChange={(e) => setPayoutAmount(Number(e.target.value))}
        ></TextField>
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={async () => {
            try {
              await RequestPayment(payOutAmount, auth.user?.id_token).then(
                (v) => {
                  showSnackbar("Payment sent", "success");
                },
              );
            } catch (e) {
              showSnackbar("Error in Payment. Contact admin", "error");
            }
          }}
        >
          Send
        </Button>
      </Box>
    );
  };

  const GamerTagCard = () => {
    // State to manage the editable gamer tags
    const [editTags, setEditTags] = useState({
      playstationGamertag: participant?.playstationGamertag || "",
      xboxGamertag: participant?.xboxGamertag || "",
      steamGamertag: participant?.steamGamertag || "",
      riotGamesGamertag: participant?.riotGamesGamertag || "",
      activisionGamertag: participant?.activisionGamertag || "",
    });

    // State to track whether the form is in edit mode
    const [isEditing, setIsEditing] = useState(false);

    // Handle input change
    const handleChange = (event: any) => {
      const { name, value } = event.target;
      setEditTags((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    // Handle save action
    const handleSave = async () => {
      try {
        await setGamerTags(auth.user?.id_token, {
          ActivisionGamertag: editTags.activisionGamertag,
          RiotGamesGamertag: editTags.riotGamesGamertag,
          SteamGamertag: editTags.steamGamertag,
          XboxGamertag: editTags.xboxGamertag,
          PlaystationGamertag: editTags.playstationGamertag,
        });
        // Optionally, switch back to read-only mode after saving
        setIsEditing(false);
      } catch (error) {
        console.error("Error saving gamer tags:", error);
      }
    };

    // Handle edit button click
    const handleEdit = () => {
      setIsEditing(true); // Set editing mode to true when user clicks edit
    };

    return (
      <Box bgcolor={"white"} p={2}>
        <Typography variant={"h4"}>GamerTags</Typography>

        <Stack direction={"row"} spacing={4}>
          <Typography>PS:</Typography>{" "}
          <TextField
            variant={"standard"}
            value={editTags.playstationGamertag}
            onChange={handleChange}
            name="playstationGamertag"
            InputProps={{
              readOnly: !isEditing, // Read-only if not editing
            }}
          />
        </Stack>

        <Stack direction={"row"} spacing={4}>
          <Typography>XBox:</Typography>
          <TextField
            variant={"standard"}
            value={editTags.xboxGamertag}
            onChange={handleChange}
            name="xboxGamertag"
            InputProps={{
              readOnly: !isEditing, // Read-only if not editing
            }}
          />
        </Stack>

        <Stack direction={"row"} spacing={4}>
          <Typography>Stream:</Typography>
          <TextField
            variant={"standard"}
            value={editTags.steamGamertag}
            onChange={handleChange}
            name="steamGamertag"
            InputProps={{
              readOnly: !isEditing, // Read-only if not editing
            }}
          />
        </Stack>

        <Stack direction={"row"} spacing={4}>
          <Typography>Riot:</Typography>
          <TextField
            variant={"standard"}
            value={editTags.riotGamesGamertag}
            onChange={handleChange}
            name="riotGamesGamertag"
            InputProps={{
              readOnly: !isEditing, // Read-only if not editing
            }}
          />
        </Stack>

        <Stack direction={"row"} spacing={4}>
          <Typography>Activision:</Typography>
          <TextField
            variant={"standard"}
            value={editTags.activisionGamertag}
            onChange={handleChange}
            name="activisionGamertag"
            InputProps={{
              readOnly: !isEditing, // Read-only if not editing
            }}
          />
        </Stack>
        {userSlicer.userId.toString() === id ? (
          <Box mt={2}>
            {isEditing ? (
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            ) : (
              <Button variant="outlined" color="secondary" onClick={handleEdit}>
                Edit
              </Button>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      color="black"
    >
      <Typography
        margin="1em"
        variant="h1"
        align="center"
        bgcolor={"secondary"}
        gutterBottom
        sx={{ fontWeight: "bold", color: "wheat" }}
      >
        PROFILE PAGE
      </Typography>
      {participant ? (
        <>
          <Banner {...participant} />
          <Achievements
            gold={participant.goldTrophy}
            silver={participant.silverTrophy}
            bronze={participant.bronzeTrophy}
          />
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <ProfileStatisticsComponent {...participant} />
                <WagerHistory />
                {tournaments.length > 0 ? (
                  <ActiveTourney tournaments={tournaments} />
                ) : (
                  <></>
                )}
                <ProfileTeam />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ position: "sticky", top: 0 }}>
                  <ProfileEarningsComponent {...participant} />
                  {userSlicer.userId.toString() === id ? (
                    <BalanceCard />
                  ) : (
                    <></>
                  )}
                  <GamerTagCard />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <Typography variant="h6" align="center">
          Loading profile...
        </Typography>
      )}
    </Box>
  );
};

const WithAuthenticationRequiredProps = {
  onRedirecting: (): React.JSX.Element => (
    <div>Redirecting to the login page...</div>
  ),
};

export default withAuthenticationRequired(
  Profile,
  // @ts-ignore
  WithAuthenticationRequiredProps,
);
