import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Theme, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { joinWager } from "../services/wagerService";
import { WagerType } from "../pages/Wager";
import { MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useAppSelector } from "../hooks";

const WagerPopup: React.FC<{ onClose: () => void; wager: WagerType }> = ({
  onClose,
  wager,
}) => {
  const theme = useTheme<Theme>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const auth = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  const handleAcceptClick = async () => {
    //setShowConfirmation(true);
    try {
      var response = await joinWager(wager.id, tag, auth.user?.id_token).then(
        (onValue) =>
          navigate("/hub", {
            state: {
              wager: wager.id,
              user: tag,
            },
          }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const HandleReturnWager = async () => {
    try {
      navigate("/hub", {
        state: { wager: wager.id, user: wager.gamerTagPlayerA },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const popupStyles = {
    width: isSmallScreen ? "17em" : isMediumScreen ? "20em" : "25em",
    height: isSmallScreen ? "17em" : isMediumScreen ? "20em" : "25em",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid black",
    backgroundColor: "white",
    position: "relative" as "relative", // Ensure the close button is positioned correctly
  };

  const headerVariant = isSmallScreen ? "h6" : isMediumScreen ? "h4" : "h1";
  const bulletPointSize = isSmallScreen
    ? "body2"
    : isMediumScreen
      ? "body1"
      : "h6";
  const buttonWidth = isSmallScreen ? "70%" : "50%";

  const confirmationStyles = {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center" as "center",
    flexGrow: 1,
  };

  const [tag, setTag] = React.useState("");
  const userRed = useAppSelector((state) => state.user.value);

  const tagList = [
    userRed.userIfo.playstationGamertag,
    userRed.userIfo.xboxGamertag,
    userRed.userIfo.riotGamesGamertag,
    userRed.userIfo.activisionGamertag,
    userRed.userIfo.steamGamertag,
  ];
  const handleChange = (event: SelectChangeEvent) => {
    setTag(event.target.value);
  };

  return (
    <Box sx={popupStyles} onClick={(e) => e.stopPropagation()}>
      <IconButton
        sx={{ position: "absolute", top: 8, right: 8 }} // Position the close button
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      {showConfirmation ? (
        <Box sx={confirmationStyles}>
          <Typography variant={headerVariant} align="center" sx={{ mb: 2 }}>
            DISCLAIMER:
          </Typography>
          <Typography variant={bulletPointSize} align="center" sx={{ mb: 4 }}>
            Leaving or Disconnecting from a Wager in Progress will result in a
            forfeit of the match. Are you ready to begin?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: buttonWidth }}
          >
            Proceed
          </Button>
        </Box>
      ) : (
        <>
          <Typography
            variant={headerVariant}
            align="center"
            sx={{
              mb: 4,
              fontSize: isSmallScreen
                ? "2em"
                : isMediumScreen
                  ? "2.5em"
                  : "3em",
            }} // Adjust margin and font size
          >
            ${wager.prizePool}
          </Typography>
          <List sx={{ alignSelf: "flex-start" }}>
            <ListItem>
              <Typography variant={bulletPointSize}>• Rules</Typography>
            </ListItem>
            <ListItem>
              <Typography variant={bulletPointSize}>• Map</Typography>
            </ListItem>
          </List>
          {wager.creatorId === userRed.userId.toString() ? (
            <></>
          ) : (
            <>
              <Typography>Available balance: {userRed.balance} $</Typography>

              <Stack direction={"row"} alignItems={"center"} spacing={4}>
                <Typography>GamerTag: </Typography>

                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={tag}
                  label="Age erwerw s"
                  onChange={handleChange}
                  size={"small"}
                >
                  {tagList
                    .filter((tag) => tag) // This removes null, undefined, and empty strings
                    .map((tag, index) => {
                      if (tag) {
                        return (
                          <MenuItem key={index} value={tag}>
                            {tag}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                </Select>
              </Stack>
            </>
          )}
          {wager.creatorId === userRed.userId.toString() ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ width: buttonWidth }}
              onClick={HandleReturnWager}
            >
              Return to Wager
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={tag.length === 0}
              sx={{ width: buttonWidth }}
              onClick={handleAcceptClick}
            >
              Accept
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default WagerPopup;
