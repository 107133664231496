import * as React from "react";
import { useEffect, useState } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import TournamentNav from "../components/TournamentNav";

import TournamentBanner from "../components/TournamentBanner";

import { useParams } from "react-router-dom";
import { BASE_URL_API } from "../services/fetchData";

const TournamentPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { tournamentId } = useParams<{ tournamentId: string }>();
  console.log(tournamentId);

  const [tournamentData, setTournamentData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_URL_API}/Tournaments/WithPlayers/${tournamentId}?withDetails=true`,
        );
        const data = await response.json();
        console.log(data);
        setTournamentData(data);
      } catch (error) {
        console.error("Error fetching tournament data:", error);
      }
    };

    fetchData();
  }, [tournamentId]);

  return (
    <div>
      <Typography
        sx={{
          fontSize: { xs: "1.5em", lg: "3em" },
          textAlign: "center",
        }}
      >
        Tournament Details
      </Typography>
      <TournamentBanner tournamentData={tournamentData} />
      <TournamentNav tournamentData={tournamentData} />
    </div>
  );
};

export default TournamentPage;
