import { Box, Typography, Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import result1 from '../images/result-img-1.png'
import result2 from '../images/result-img-2.png'
import result3 from '../images/result-img-3.png'
import game from '../images/game-left.png'
import Admins from './Admins'
import p1 from '../images/participants-1.png'
import p2 from '../images/participants-2.png'
import p3 from '../images/participants-3.png'
import p4 from '../images/participants-4.png'
import p5 from '../images/participants-5.png'

interface TournamentInfoProps {
  tournamentData: any;
}



const InfoBox: React.FC<TournamentInfoProps> = ({tournamentData}) => {
  console.log(tournamentData)
  const extraAvatar = '';
  const avatars = [
    p1,
    p2,
    p3,
    p4,
    p5,
    
  ];

  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, m: 1, bgcolor: 'transparent', width: '20em' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Teams
      </Typography>
      <Box sx={{ padding: '1em',backgroundColor:'white', borderRadius:'1em', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Registered</span>
          <span>{tournamentData.registeredTeamCount}</span>
        </Typography>
        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Confirmed</span>
          <span>{tournamentData.detail.confirmedTeamCount}</span>
        </Typography>
        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Available slots</span>
          <span>{tournamentData.detail.availableSlots}</span>
        </Typography>
      </Box>
      <Box sx={{ padding:'1em', marginBottom:'4em',display: 'flex', flexDirection: 'row', position: 'relative',  maxWidth: '100%' }}>
          {avatars.map((avatar, index) => (
              <Avatar
              key={index}
              src={avatar}
              sx={{
                  position: 'absolute', 
                  left: `${index * 20}px`, 
                  zIndex: avatars.length - index,
                  width: '3em',
                  height: '3em', 
              }}
              />
          ))}
          <Avatar
              src={extraAvatar}
              sx={{
                  position: 'absolute', 
                  right: '0px', 
                  zIndex: avatars.length + 1,
                  width: '3em',
                  height: '3em', 
              }}
          />
      </Box>
      <Box sx={{ width:'100%', marginBottom:'1em',display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, bgcolor:'transparent'}}>
        <Typography variant="h6" component="div">
          RESULTS
        </Typography>
        <Button variant="contained">
          VIEW ALL 
        </Button>
      </Box>
      <Box sx={{ 
            backgroundPosition: 'center', 
            borderRadius:'1.5em', 
            marginBottom:'1em',
            width: '20.5em', 
            height: '18em', 
            backgroundImage: `url(${result1})`, 
            backgroundSize: 'cover', 
            display: 'flex', 
            alignItems: 'flex-start',
            position: 'relative',
        }}>            
            <Box sx={{ 
                padding: '1em', 
                marginTop: '1em', 
                width: '100%', 
                height: '3em', 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems:'center',
                position: 'relative', 
                zIndex: 1, 
                '::before': { 
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'background.paper',
                    opacity: 0.4
                }
            }}>                
                <span style={{ color: 'white' }}>1st</span>
                <span style={{ color: 'white' }}>$60.0</span>
            </Box>
        </Box>
        <Box sx={{ 
            backgroundPosition: 'center', 
            borderRadius:'1.5em', 
            marginBottom:'1em',
            width: '20.5em', 
            height: '18em', 
            backgroundImage: `url(${result2})`, 
            backgroundSize: 'cover', 
            display: 'flex', 
            alignItems: 'flex-start',
            position: 'relative' 
        }}>            
            <Box sx={{ 
                padding: '1em', 
                marginTop: '1em', 
                width: '100%', 
                height: '3em', 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems:'center',
                position: 'relative', 
                zIndex: 1, 
                '::before': { 
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'background.paper',
                    opacity: 0.4
                }
            }}>                
                <span style={{ color: 'white' }}>1st</span>
                <span style={{ color: 'white' }}>$60.0</span>
            </Box>
        </Box>
        <Box sx={{ 
            backgroundPosition: 'center', 
            borderRadius:'1.5em', 
            marginBottom:'1em',
            width: '20.5em', 
            height: '18em', 
            backgroundImage: `url(${result3})`, 
            backgroundSize: 'cover', 
            display: 'flex', 
            alignItems: 'flex-start',
            position: 'relative' 
        }}>            
            <Box sx={{ 
                padding: '1em', 
                marginTop: '1em', 
                width: '100%', 
                height: '3em', 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems:'center',
                position: 'relative', 
                zIndex: 1, 
                '::before': { 
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'background.paper',
                    opacity: 0.4
                }
            }}>                
                <span style={{ color: 'white' }}>1st</span>
                <span style={{ color: 'white' }}>$60.0</span>
            </Box>
      </Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Tournament
      </Typography>
      <Box sx={{ width: '22em', height: '16em', display: 'flex' }}>
        <Box sx={{ borderRadius:'2em', backgroundPosition: 'center', width: '8em', height: '11em',flex: '1 1 auto', backgroundImage: `url(${tournamentData.imageUrl})`, backgroundSize: 'cover',}} />
        <Box sx={{marginLeft:'2em', width: '8em', height: '11em', flex: '1 1 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
            <Typography variant="h6" component="div" gutterBottom>
                GAME
            </Typography>
            <Typography variant="body1" component="div">
                {tournamentData.gameName}
            </Typography>
        </Box>
      </Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Admins (3)
      </Typography>
      <Admins />
    </Box>
  );
};

export default InfoBox;
