import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Team as TeamData } from "../utils/types/Team";
import { BASE_URL_API } from "../services/fetchData";

const TeamCard: React.FC<{ team: TeamData }> = ({ team }) => {
  const auth = useAuth();
  const idToken = auth.user?.id_token; // Get the ID token
  const navigate = useNavigate();

  return (
    <Grid item key={team.id} xs={12} sm={6} md={4}>
      <Box bgcolor="primary.light" py="24px" px="0px" borderRadius="16px">
        <Box display="flex" justifyContent="center" alignItems="center">
          {team.imageUrl && (
            <Avatar src={team.imageUrl} sx={{ width: 72, height: 72 }} />
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pt="15px"
        >
          <Typography variant="h5" component="div" color={"wheat"}>
            {team.name}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            Members: {team.maxMembers}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

function ProfileTeam() {
  const [teams, setTeams] = useState<TeamData[]>([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch(`${BASE_URL_API}/Teams`);
        const data = await response.json();
        setTeams(data);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, []);

  return (
    <div>
      <div style={{ padding: "0 16px" }}>
        <Typography variant="h4" component="h1" color={"wheat"} gutterBottom>
          Teams
        </Typography>
        <Grid container spacing={4} alignItems="center">
          {teams.map((team) => (
            <TeamCard key={team.id} team={team} />
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default ProfileTeam;
