import React from "react";
import FootLogo from "../images/eSportiumFooterLogo.png";
import { useNavigate } from "react-router-dom";
import { Facebook, Instagram, X } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";

const Footer = () => {
  const theme = useTheme();
  const links = {
    Home: "/",
    "About Us": "/about",
    Contact: "/contact",
  };

  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  return (
    <Box marginTop={10}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <footer>
          <Grid
            container
            justifyContent="space-between"
            direction={{ xs: "column", md: "row" }}
          >
            <Grid
              container
              item
              xs={4}
              justifyContent={{ xs: "center", md: "left" }}
              alignItems="center"
              py={{ sm: 1, md: 2 }}
            >
              <img src={FootLogo} alt="logo" width={"240vw"} />
            </Grid>
            <Grid
              container
              item
              xs={4}
              justifyContent="center"
              alignItems="center"
              py={{ sm: 1, md: 1 }}
            >
              {Object.entries(links).map(([key, value]) => (
                <Button
                  key={key}
                  onClick={() => handleClick(value)}
                  sx={{
                    color: theme.palette.text.secondary,
                    "&:hover": {
                      color: theme.palette.primary.dark,
                    },
                  }}
                >
                  {key}
                </Button>
              ))}
            </Grid>
            <Grid
              container
              item
              xs={4}
              justifyContent={{ xs: "center", md: "right" }}
              alignItems="center"
              py={{ sm: 1, md: 1 }}
            >
              <Stack spacing={2} direction="row">
                <IconButton
                  sx={{
                    color: theme.palette.text.secondary,
                    "&:hover": {
                      color: theme.palette.primary.dark,
                    },
                  }}
                  onClick={() => window.open("")}
                >
                  <Facebook />
                </IconButton>
                <IconButton
                  sx={{
                    color: theme.palette.text.secondary,
                    "&:hover": {
                      color: theme.palette.primary.dark,
                    },
                  }}
                  onClick={() =>
                    window.open("https://twitter.com/play_esportium")
                  }
                >
                  <X />
                </IconButton>
                <IconButton
                  sx={{
                    color: theme.palette.text.secondary,
                    "&:hover": {
                      color: theme.palette.primary.dark,
                    },
                  }}
                  onClick={() =>
                    window.open("https://www.instagram.com/esportium.gg/")
                  }
                >
                  <Instagram />
                </IconButton>
              </Stack>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              py={2}
              sx={{
                borderTop: `1px solid ${theme.palette.text.secondary}`,
              }}
            >
              <Typography>
                Copyright &copy; 2021. All Rights Reserved By Esportium
              </Typography>
            </Grid>
          </Grid>
        </footer>
      </Box>
    </Box>
  );
};

export default Footer;
