import { WebStorageStateStore } from "oidc-client-ts";
import { FRONTEND_URL, FRONTEND_URL_LOCAL } from "./services/fetchData";

const authConfig = {
  authority: "https://accounts.google.com",
  client_id:
    "873932430728-1tvlklnr1qq4i0mgc9dsbnlvrgk7sqth.apps.googleusercontent.com",
  client_secret: "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",
  redirect_uri: `${FRONTEND_URL}/`,
  response_type: "code",
  scope: "openid profile email",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

export default authConfig;
