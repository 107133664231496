import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reducer";
import { UserData } from "../pages/Profile";

// Define a type for the slice state
// Define a type for the slice state
export interface UserState {
  value: {
    userId: number;
    userIfo: UserData;
    balance: number;
  };
}

// Define the initial state using that type
const initialState: UserState = {
  value: {
    userIfo: {
      activisionGamertag: null,
      bronzeTrophy: 0,
      email: "",
      firstName: "",
      goldTrophy: 0,
      id: 0,
      isOnline: false,
      isVerified: false,
      lastName: "",
      picture: "",
      playstationGamertag: null,
      profileViews: 0,
      riotGamesGamertag: null,
      silverTrophy: 0,
      steamGamertag: null,
      totalGamesPlayed: 0,
      totalMoneyEarned: 0,
      totalTournamentPlayed: 0,
      totalTournamentsWon: 0,
      totalWagersPlayed: 0,
      tournamentMoneyEarned: 0,
      wagerMoneyEarned: 0,
      xboxGamertag: null,
    },
    balance: 0,
    userId: 0,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<UserData>) => {
      console.log(action.payload);
      state.value.userIfo = action.payload;
    },

    updateId: (state, action: PayloadAction<number>) => {
      state.value.userId = action.payload;
    },

    updateBalance: (state, action: PayloadAction<number>) => {
      state.value.balance = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUserInfo, updateId, updateBalance } = userSlice.actions;

export default userSlice.reducer;
