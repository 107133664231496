import React, { SyntheticEvent, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Stack,
  styled,
  Tab,
  Tabs,
} from "@mui/material";
import Banner from "./Banner";
import Character from "../images/character.png";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { useTheme }from "@mui/material";

const ParallelogramBox = styled(Box)(({ theme }) => ({
  width: "369px",
  height: "100%",
  backgroundColor: theme.palette.primary.dark,
  transform: "skewX(-18deg)", 
  position: "absolute",
  display: "none",
  [theme.breakpoints.up("lg")]: {
    transform: "skewX(-18deg)",
    display: "block", 
  },
  [theme.breakpoints.up("xl")]: {
    transform: "skewX(-35deg)", 
  },
}));

const slides = [
  {
    title: "Leader Cup #7",
    date: "26 SEP",
    registrationStatus: "Registration Opened",
    img: Character,
    countdown: { day: "00", hr: "00", min: "00", sec: "00" },
  },
  {
    title: "Leader Cup #8",
    date: "27 OCT",
    registrationStatus: "Registration Closed",
    img: Character,
    countdown: { day: "01", hr: "12", min: "30", sec: "45" },
  },
  {
    title: "Leader Cup #9",
    date: "28 NOV",
    registrationStatus: "Registration Opened",
    img: Character,
    countdown: { day: "02", hr: "08", min: "20", sec: "35" },
  },
  {
    title: "Leader Cup #10",
    date: "29 NOV",
    registrationStatus: "Registration Closed",
    img: Character,
    countdown: { day: "03", hr: "04", min: "10", sec: "55" },
  },
];

function HighlightTournament() {
  const theme = useTheme()
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    setCurrentSlide(newValue);
  };


  const slide = slides[currentSlide];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      pt={{ xs: "78px", lg: "148px", xl: "198px" }}
      
    >
      <Box
        px="15px"
        maxWidth={{ xl: "1200px", lg: "1100px", md: "720px", sm: "540px" }}
        width="100%"
        position="relative"
      >
        <Banner title="Tournament" />
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ background: theme.palette.primary.main }}
          borderRadius="10px"
          position="relative"
          border={`1px solid ${theme.palette.primary.dark}`}
        >
          <Button
            onClick={handlePrev}
            sx={{
              width: "25px",
              height: "60px",
              background: theme.palette.primary.dark,
              borderRadius: "5px",
              color: "#ffffff",
              minWidth: "0px",
              position: "absolute",
              zIndex: "2",
              left: "0px",
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
              },
            }}
          >
            <NavigateBefore />
          </Button>
          <Box width="100%" display="inline-block" position="relative">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <Grid
                item
                xs={12}
                lg={5}
                p={{ xs: "25px 0px", lg: "25px 60px" }}
                justifyContent={{ xs: "center", lg: "left" }}
                alignItems="center"
                position="relative"
              >
                <Stack
                  justifyContent={{ xs: "center", lg: "left" }}
                  alignItems={{ xs: "center", lg: "flex-start" }}
                  position="relative"
                >
                  <Typography color={theme.palette.primary.dark} fontWeight="500" fontFamily={"Orbitron, Arial, sans-serif"} letterSpacing={1}>
                    {slide.registrationStatus}
                  </Typography>
                  <Typography
                    color="white"
                    fontSize="32px"
                    lineHeight="38px"
                    pt="20px"
                    pb="30px"
                    variant="h4"
                    
                  >
                    {slide.title}
                  </Typography>
                  <Box display="flex">
                    <Box
                      p="8px 10px"
                      borderRadius="5px"
                      sx={{ background: theme.palette.primary.light }}
                      color="#ffffff"
                      mr="5px"
                    >
                      <Typography variant="h5" textAlign="center">
                        {slide.date.split(" ")[0]}
                      </Typography>
                      <Typography textAlign="center" mx="15px" fontSize="13px">
                        {slide.date.split(" ")[1]}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Box mx="5px" color="#ffffff">
                        <Typography
                          variant="h6"
                          sx={{ background: theme.palette.primary.light }}
                          borderRadius="5px"
                          textAlign="center"
                          p="5px 10px"
                        >
                          {slide.countdown.day}
                        </Typography>
                        <Typography fontSize="13px" textAlign="center" pt="5px">
                          DAY
                        </Typography>
                      </Box>
                      <Box mx="5px" color="#ffffff">
                        <Typography
                          variant="h6"
                          sx={{ background: theme.palette.primary.light }}
                          borderRadius="5px"
                          textAlign="center"
                          p="5px 10px"
                        >
                          {slide.countdown.hr}
                        </Typography>
                        <Typography fontSize="13px" textAlign="center" pt="5px">
                          HR
                        </Typography>
                      </Box>
                      <Box mx="5px" color="#ffffff">
                        <Typography
                          variant="h6"
                          sx={{ background: theme.palette.primary.light }}
                          borderRadius="5px"
                          textAlign="center"
                          p="5px 10px"
                        >
                          {slide.countdown.min}
                        </Typography>
                        <Typography fontSize="13px" textAlign="center" pt="5px">
                          MIN
                        </Typography>
                      </Box>
                      <Box mx="5px" color="#ffffff">
                        <Typography
                          variant="h6"
                          sx={{ background: theme.palette.primary.light }}
                          borderRadius="5px"
                          textAlign="center"
                          p="5px 10px"
                        >
                          {slide.countdown.sec}
                        </Typography>
                        <Typography fontSize="13px" textAlign="center" pt="5px">
                          SEC
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      
                      variant="contained"
                      sx={{ mt: "35px" , backgroundColor: theme.palette.primary.light, fontFamily: "Orbitron, Arial, sans-serif", px: 4,
                        py: 1, border: `1px solid ${theme.palette.primary.dark}`}}
                      href="/about"
                    
                    >
                      JOIN NOW
                    </Button>
                  </Box>
                </Stack>
              </Grid>
              <Grid
                container
                item
                xs={12}
                lg={7}
                px="15px"
                justifyContent={{ xs: "center", lg: "initial" }}
                position="relative"
                zIndex="0"
              >
                <ParallelogramBox />
                <img src={slide.img} style={{ position: "relative" }} />
              </Grid>
            </Grid>
          </Box>
          <Button
            onClick={handleNext}
            sx={{
              width: "25px",
              position: "absolute",
              right: "0",
              zIndex: "1",
              height: "60px",
              background: theme.palette.primary.dark,
              borderRadius: "5px",
              color: "#ffffff",
              minWidth: "0px",
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
              },
            }}
          >
            <NavigateNext />
          </Button>
          <Box sx={{ position: "absolute", right: "10%", bottom: "10%" }}>
            <Tabs
              value={currentSlide}
              onChange={handleTabChange}
              centered
              textColor="inherit"
              indicatorColor="primary"
              TabIndicatorProps={{
                sx: {
                  display: "none", // Hide the default indicator
                },
              }}
            >
              {slides.map((slide, index) => (
                <Tab
                  key={index}
                  sx={{
                    width: "8px",
                    height: "8px",
                    minWidth: "8px",
                    minHeight: "8px",
                    borderRadius: "2px",
                    backgroundColor: "#FFFFFF99",
                    margin: "0 5px",
                    padding: "0",
                    display: "inline-block",
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.primary.dark,
                      width: "40px",
                      height: "8px",
                      borderRadius: "2px",
                    },
                  }}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default HighlightTournament;
