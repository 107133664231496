import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import Character1 from "../images/character_01.png";
import Character2 from "../images/character_02.png";
import WeeklyShowdown from "../images/weekly-showdown.png";
import { useLocation } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useSnackbar } from "./SnackbarProvider";
import TeamCreation from "./TeamCreation";
import { BASE_URL_API } from "../services/fetchData";

interface Team {
  id: string;
  name: string;
  maxMembers: number;
  tournaments: [];
}

// Example data
const tournamentDummyData = {
  id: "1",
  name: "WEEKLY SHOWDOWN",
  description: "CS:GO WEEKLY SHOWDOWN",
  startTime: "2024-08-21T05:00:00Z",
  prize: 739,
  joinUrl: "/join/tournament/1",
  imageUrl1: Character1,
  imageUrl2: Character2,
};

const TournamentBanner = ({ tournamentData }: { tournamentData: any }) => {
  const auth = useAuth();
  const theme = useTheme();
  const { description, startTime, prize, joinUrl, imageUrl1, imageUrl2 } =
    tournamentDummyData;
  const { teamSize } = tournamentData ?? {};
  const [teamId, setTeamId] = useState("");
  const [teams, setTeams] = useState<Team[]>([]);
  const location = useLocation();
  const tournamentId = location.pathname.split("/").pop();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const calculateTimeLeft = () => {
    const difference = +new Date(startTime) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  useEffect(() => {
    const fetchTeams = async () => {
      if (!auth.isAuthenticated) return;

      const idToken = auth.user?.id_token;
      try {
        const response = await fetch(`${BASE_URL_API}/User/admin/teams`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTeams(data);
        } else {
          console.error("Failed to fetch teams");
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchTeams();
  }, [auth]);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const countdown = `${timeLeft.days}d ${timeLeft.hours}H ${timeLeft.minutes}M ${timeLeft.seconds}S`;

  const { showSnackbar } = useSnackbar();

  const handleJoinTournament = async () => {
    if (!auth.isAuthenticated) {
      // Redirect user to login page or show a login modal
      showSnackbar("Please log in before joining!", "info");
      return;
    }

    if (teams.length === 0) {
      setIsDialogOpen(true);
    } else {
      const idToken = auth.user?.id_token;
      if (idToken) {
        try {
          let endpoint = "";
          let requestBody = {};

          if (tournamentData.teamSize === "1v1") {
            endpoint = `${BASE_URL_API}/Tournaments/JoinUser/${tournamentId}`;
          } else {
            endpoint = `${BASE_URL_API}/Tournaments/JoinTeam`;
            requestBody = {
              teamId: teamId,
              tournamentId,
            };
          }

          const response = await fetch(endpoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify(requestBody),
          });

          if (response.ok) {
            if (tournamentData.teamSize === "1v1") {
              showSnackbar("User joined tournament successfully", "success");
            } else {
              showSnackbar("Team joined tournament successfully", "success");
            }
          } else {
            const errorData = await response.json();
            showSnackbar(errorData.detail, "error");
          }
        } catch (error) {
          console.error("Error joining:", error);
          showSnackbar(
            "An error occurred while joining the tournament.",
            "error",
          );
        }
      }
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Container>
      <Card
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: "white",
          borderRadius: 2,
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.light,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              [theme.breakpoints.up("sm")]: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                textAlign: "center",
              },
            }}
          >
            <CardMedia
              component="img"
              image={imageUrl1}
              sx={{
                ml: { xs: 0, sm: 0, md: 4, lg: 8, xl: 8 },
                height: { xs: 225, sm: 175, md: 225 },
                width: "auto",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                my: { xs: 2, sm: 0 },
              }}
            >
              <CardMedia
                component="img"
                image={WeeklyShowdown}
                sx={{
                  height: "auto",
                  width: { xs: 150, sm: 100 },
                  alignContent: "center",
                }}
              />

              <Typography
                variant="h5"
                sx={{ fontSize: { sm: "14px" }, fontWeight: "bold" }}
              >
                {description}
              </Typography>
            </Box>
            <CardMedia
              component="img"
              image={imageUrl2}
              sx={{
                mr: { xs: 0, sm: 0, md: 4, lg: 8, xl: 8 },
                height: { xs: 225, sm: 175, md: 225 },
                width: "auto",
              }}
            />
          </Box>

          <Grid
            container
            alignItems="center"
            sx={{ mt: 3, px: { xs: 2, sm: 2, md: 4, lg: 8 } }}
          >
            <Grid item xs={12} sm={8} md={7}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "1.5rem", sm: "1.5rem" },
                  textAlign: { xs: "center", sm: "left" },
                  fontWeight: "bold",
                  mb: 3,
                }}
              >
                HEAD 2 HEAD - WEEKLY - SHOWDOWN
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                }}
              >
                <Typography
                  sx={{
                    p: 1,
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: 2,
                    textAlign: { xs: "center", sm: "left" },
                    fontSize: "12px",
                    mb: { xs: 1, sm: 0 },
                  }}
                >
                  <Box
                    component="span"
                    sx={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <span
                      role="img"
                      aria-label="hourglass"
                      style={{ marginRight: "0.5rem" }}
                    >
                      ⏳
                    </span>
                    Starts in{" "}
                    <Box
                      component="span"
                      sx={{
                        color: "cyan",
                        fontWeight: "bold",
                        marginLeft: "0.5rem",
                      }}
                    >
                      {countdown}
                    </Box>
                  </Box>
                </Typography>
                <Typography
                  sx={{
                    p: 1,
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: 2,
                    fontSize: "12px",
                    textAlign: { xs: "center", sm: "left" },
                    ml: { xs: 0, sm: 4 },
                  }}
                >
                  {formatDate(startTime)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: { xs: "center", sm: "flex-end" },
                textAlign: { xs: "center", sm: "right" },
                height: "100%",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "2.5rem", color: "limegreen" }}
              >
                ${prize}
              </Typography>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
            px="32px"
            mt={2}
          >
            {teamSize !== "1v1" && (
              <FormControl fullWidth>
                <Select
                  value={teamId}
                  onChange={(e) => setTeamId(e.target.value)}
                  sx={{ backgroundColor: "white" }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    Select Team
                  </MenuItem>
                  {teams.map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button
              variant="contained"
              onClick={handleJoinTournament}
              sx={{
                background: theme.palette.primary.light,
                color: "white",
                boxShadow: 0,
                borderRadius: 2,
              }}
            >
              JOIN!
            </Button>
            <TeamCreation isOpen={isDialogOpen} onClose={handleCloseDialog} />
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default TournamentBanner;
