import React from 'react'
import TeamLeaderDashboard from '../components/TeamDashboard';
import { useParams } from 'react-router-dom';

const TeamDashboard = () => {
  const { teamId } = useParams<{ teamId: string }>();
  console.log(teamId);

  return (
    <div>
      <TeamLeaderDashboard teamId={teamId as string}/>
    </div>
  )
}

export default TeamDashboard;