import React, { useEffect, useState } from "react";
import {
  Bracket,
  IRenderSeedProps,
  IRoundProps,
  Seed,
  SeedItem,
  SeedTeam,
} from "react-brackets";
import { Box, Grid, Typography } from "@mui/material";
import Profile from "./../images/profile-logo.png";
import { Team as TeamData } from "../utils/types/Team";
import { BASE_URL_API } from "../services/fetchData";

// Function to pad the teams list to the nearest power of 2
const padTeams = (teams: any[]) => {
  const totalTeams = teams.length;
  const nextPowerOf2 = Math.pow(2, Math.ceil(Math.log2(totalTeams)));
  const paddedTeams = [...teams];

  while (paddedTeams.length < nextPowerOf2) {
    paddedTeams.push("BYE");
  }

  return paddedTeams;
};

// Function to shuffle the teams
const shuffleTeams = (teamNames: any[]) => {
  for (let i = teamNames.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [teamNames[i], teamNames[j]] = [teamNames[j], teamNames[i]];
  }
  return teamNames;
};

// Function to generate teams with random scores
const generateTeams = (teamNames: any[]) => {
  return teamNames.map((name: any, index: number) => ({
    id: index + 1,
    name,
    score: Math.floor(Math.random() * 10),
  }));
};

// Function to generate matches from teams
const generateMatches = (teams: string | any[]) => {
  const matches = [];
  for (let i = 0; i < teams.length; i += 2) {
    matches.push({
      id: i / 2 + 1,
      date: new Date().toISOString(),
      teams: [
        teams[i],
        teams[i + 1] ? teams[i + 1] : { id: 0, name: "BYE", score: 0 },
      ],
    });
  }
  return matches;
};

// Function to get winners from matches
const getWinners = (matches: any[]) => {
  return matches.map((match: { teams: any[] }) =>
    match.teams[1].name === "BYE" || match.teams[0].score > match.teams[1].score
      ? match.teams[0]
      : match.teams[1],
  );
};

// Function to generate rounds
const generateRounds = (teamNames: string[]) => {
  const shuffledTeamNames = shuffleTeams(teamNames);
  const paddedTeamNames = padTeams(shuffledTeamNames);
  const numRounds = Math.ceil(Math.log2(paddedTeamNames.length));
  const rounds = [];
  let teams = generateTeams(paddedTeamNames);

  for (let i = 1; i <= numRounds; i++) {
    const matches = generateMatches(teams);
    rounds.push({
      title: `Round ${i}`,
      seeds: matches,
    });
    // Winners move to the next round
    teams = getWinners(matches);
  }
  return rounds;
};

function BracketProp() {
  const [rounds, setRounds] = useState<IRoundProps[]>([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch(`${BASE_URL_API}/Teams`);
        const data = await response.json();
        const teamNames = data.map((team: TeamData) => team.name); // Extract team names
        const dynamicRounds = generateRounds(teamNames); // Pass team names to generateRounds
        setRounds(dynamicRounds);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchTeams();
  }, []);

  const CustomSeed = ({
    seed,
    breakpoint,
    roundIndex,
    seedIndex,
  }: IRenderSeedProps) => {
    const homeTeam = seed.teams[0];
    const awayTeam = seed.teams[1];

    return (
      <Seed
        mobileBreakpoint={breakpoint}
        style={{
          fontSize: 12,
          width: window.innerWidth <= breakpoint ? "revert-layer" : "300px",
        }}
      >
        <SeedItem
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <div>
            <SeedTeam
              style={{
                backgroundColor: "transparent",
                padding: "1px 0",
              }}
            >
              <Grid
                container
                display="flex"
                alignItems="center"
                direction="row"
              >
                <Grid item xs={10}>
                  <Box
                    display="flex"
                    sx={{
                      alignItems: "center",
                      backgroundColor: "#1C0A68",
                      padding: "10px",
                      borderRadius: "5px 0 0 5px",
                    }}
                  >
                    <img
                      src={Profile}
                      width="30px"
                      style={{ marginRight: "5px" }}
                    />
                    {homeTeam.name ? homeTeam.name : "----"}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    sx={{
                      backgroundColor: "#4C1CF3",
                      padding: "16px",
                      borderRadius: "0 5px 5px 0",
                    }}
                  >
                    {homeTeam.score}
                  </Box>
                </Grid>
              </Grid>
            </SeedTeam>
            <SeedTeam
              style={{
                backgroundColor: "transparent",
                padding: "1px 0",
              }}
            >
              <Grid
                container
                display="flex"
                alignItems="center"
                direction="row"
              >
                <Grid item xs={10}>
                  <Box
                    display="flex"
                    sx={{
                      alignItems: "center",
                      backgroundColor: "#1C0A68",
                      padding: "10px",
                      borderRadius: "5px 0 0 5px",
                    }}
                  >
                    <img
                      src={Profile}
                      width="30px"
                      style={{ marginRight: "5px" }}
                    />
                    {awayTeam.name ? awayTeam.name : "----"}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    sx={{
                      backgroundColor: "#025E5F",
                      padding: "16px",
                      borderRadius: "0 5px 5px 0",
                    }}
                  >
                    {awayTeam.score}
                  </Box>
                </Grid>
              </Grid>
            </SeedTeam>
          </div>
        </SeedItem>
      </Seed>
    );
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        display={{ lg: "flex" }}
        justifyContent="center"
        width={{ xs: "100%", lg: "0%" }}
        mt="10px"
        px="5px"
      >
        <Bracket
          rounds={rounds}
          roundTitleComponent={(title: React.ReactNode, roundIndex: number) => {
            return (
              <Box
                key={roundIndex}
                px="25px"
                py="30px"
                mx="20px"
                sx={{ backgroundColor: "#240773", borderRadius: "10px" }}
              >
                <Typography
                  variant="h5"
                  color="#ffffff"
                  textAlign="center"
                  mb="15px"
                  fontWeight="bold"
                >
                  {title}
                </Typography>
                <Typography color="#00FADF" textAlign="center">
                  Matches {rounds[roundIndex].seeds.length}
                </Typography>
              </Box>
            );
          }}
          renderSeedComponent={CustomSeed}
          swipeableProps={{
            enableMouseEvents: true,
            animateHeight: true,
          }}
        />
      </Box>
      <style>
        {`
          [dir="ltr"] .sc-imWYAI:nth-child(2n + 1):not(:last-child)::after {
            border-right: 3px solid white !important;
            border-top: 3px solid white;
          }
          [dir="ltr"] .sc-imWYAI:nth-child(2n)::before {
            border-top: 3px solid white;
          }
          [dir="ltr"] .sc-imWYAI:nth-child(2n)::after {
            border-right: 3px solid white !important;
            border-bottom: 3px solid white;
            }
        `}
      </style>
    </Box>
  );
}

export default BracketProp;
