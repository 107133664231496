import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Team as TeamData } from "../utils/types/Team";
import TeamCreation from "../components/TeamCreation";
import { useAuth } from "react-oidc-context";
import AddIcon from "@mui/icons-material/Add";
import { BASE_URL_API } from "../services/fetchData";

const TeamCard: React.FC<{ team: TeamData }> = ({ team }) => {
  const auth = useAuth();
  const idToken = auth.user?.id_token; // Get the ID token
  const navigate = useNavigate();

  const handleViewTeam = () => {
    navigate(`/team-dashboard/${team.id}`);
  };

  const handleJoinTeam = async () => {
    try {
      // Check if the user is already in the team
      const checkResponse = await fetch(
        `${BASE_URL_API}/Teams/${team.id}/IsUserInTeam`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      if (!checkResponse.ok) {
        throw new Error("Failed to check user in team");
      }

      const isUserInTeam = await checkResponse.json();

      if (isUserInTeam) {
        alert("You are already in the team.");
        return;
      }

      const response = await fetch(`${BASE_URL_API}/Teams/Join/${team.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });
      console.log(response);
      if (response.ok) {
        navigate(`/team-dashboard/${team.id}`);
        setTimeout(() => {
          alert("You joined the team!");
        }, 100);
      } else {
        alert("Team is full. Cannot join.");
      }
    } catch (error) {
      console.error("Failed to join team:", error);
    }
  };

  return (
    <Grid item key={team.id} xs={12} sm={6} md={3}>
      <Card sx={{ maxWidth: 500 }}>
        {team.imageUrl && (
          <CardMedia
            component="img"
            height="200"
            image={team.imageUrl}
            alt={`${team.name} logo`}
          />
        )}
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h5" component="div">
                {team.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {team.description}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Max Members: {team.maxMembers}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" component="div">
                Entry Fee: {team.entryFee} Credits
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                style={{ margin: "8px 0" }}
                onClick={handleViewTeam}
              >
                View Team
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "8px 10px" }}
                onClick={handleJoinTeam}
              >
                Join Team
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

const TeamTable: React.FC<{ team: TeamData[] }> = ({ team }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTeams, setFilteredTeams] = useState(team);
  const [requestStatus, setRequestStatus] = useState<{
    [key: number]: boolean;
  }>({});
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleViewTeam = (teamID: string) => {
    navigate(`/team-dashboard/${teamID}`);
  };

  useEffect(() => {
    setFilteredTeams(
      team.filter((teams) =>
        teams.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    );
  }, [searchQuery, team]);

  const handleRequest = (index: number) => {
    console.log("request sent");
    setRequestStatus((prevStatus) => ({ ...prevStatus, [index]: true }));
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box p={3}>
      <TeamCreation isOpen={isDialogOpen} onClose={handleCloseDialog} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          Teams
        </Typography>
        <Button
          variant="contained"
          onClick={handleOpenDialog}
          sx={{ pl: "10px" }}
        >
          <AddIcon sx={{ mr: "5px" }} /> Create Team
        </Button>
      </Box>

      <Box display="flex" mb={2}>
        <TextField
          label="Search Team"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Logo</TableCell>
              <TableCell>Team Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Members</TableCell>
              <TableCell>Join Team</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTeams.map((teams, index) => (
              <TableRow key={index}>
                <TableCell>
                  {teams.imageUrl && (
                    <img
                      src={teams.imageUrl}
                      alt={`${teams.name} logo`}
                      style={{ width: 50, height: 50 }}
                    />
                  )}
                </TableCell>
                <TableCell>{teams.name}</TableCell>
                <TableCell>{teams.description}</TableCell>
                <TableCell>{teams.maxMembers}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ mr: "10px" }}
                    onClick={() => handleViewTeam(teams.id)}
                  >
                    View
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleRequest(index)}
                    disabled={requestStatus[index]}
                  >
                    {requestStatus[index] ? "Sent" : "Request"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const Teams: React.FC = () => {
  const [teams, setTeams] = useState<TeamData[]>([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch(`${BASE_URL_API}/api/Teams`);
        const data = await response.json();
        setTeams(data);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchTeams();
  }, []);

  return (
    <div>
      <div style={{ padding: "0 16px" }}>
        {/* <Typography variant="h4" component="h1" gutterBottom>
          Available Teams
        </Typography>
        <Grid container spacing={4} alignItems="center">
          {teams.map((team) => (
            <TeamCard key={team.id} team={team} />
          ))}
        </Grid> */}
        <TeamTable team={teams} />
      </div>
    </div>
  );
};

export default Teams;
