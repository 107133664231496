import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PayPal from "../components/PayPal";
import Summary from "../components/PurchaseSummary";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";

const Checkout = () => {
  const auth = useAuth();

  const subtotal = 100;
  const taxesAndFees = 20;

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        p: 2,
      }}
    >
      <Typography variant="h4" textAlign="center">
        Checkout
      </Typography>
      <Box
        sx={{
          height: "18em",
          width: "50em",
          display: "flex",
          justifyContent: "center",
          m: 2,
        }}
      >
        <Box
          sx={{
            width: "60%",
            height: "100%",
            mr: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h4">Payment method</Typography>
          <Typography marginBottom="2em" variant="body1">
            Please enter your payment method
          </Typography>
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "1em",
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PayPal />
          </Box>
        </Box>
        <Box sx={{ width: "40%", height: "100%", ml: 4 }}>
          <Summary subtotal={subtotal} taxesAndFees={taxesAndFees} />
        </Box>
      </Box>
    </Box>
  );
};

export const WithAuthenticationRequiredProps = {
  onRedirecting: (): React.JSX.Element => (
    <div>Redirecting to the login page...</div>
  ),
};

export default withAuthenticationRequired(
  Checkout,
  // @ts-ignore
  WithAuthenticationRequiredProps,
);
