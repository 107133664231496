import { BASE_URL_API } from "../services/fetchData";

export const uploadImage = async (
  file: File,
  accessToken: string,
  path: string,
) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await fetch(`${BASE_URL_API}/${path}/upload`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Failed to upload image");
  }

  return await response.json();
};

export const uploadProofToWager = async (
  file: File,
  accessToken: string,
  wagerId: string,
) => {
  // Validate the file type
  const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
  if (!allowedTypes.includes(file.type)) {
    throw new Error(
      "Invalid file type. Please upload a PDF or an image (JPEG/PNG).",
    );
  }

  const formData = new FormData();
  formData.append("file", file);
  const response = await fetch(`${BASE_URL_API}/wager/upload/${wagerId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  });

  const result = await response.json();
  console.log(result);
  if (!response.ok) {
    throw new Error(`${result.detail}`);
  }

  return result;
};
