import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Tabs,
  Tab,
  Grid,
  Divider,
} from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import ProfileStatCard from "./ProfileStatCard";

import StatisticIcon1 from "../images/statistics-icon-1.png";
import StatisticIcon2 from "../images/statistics-icon-2.png";
import StatisticIcon3 from "../images/statistics-icon-3.png";
import StatisticIcon4 from "../images/statistics-icon-4.png";
import StatisticIcon5 from "../images/ranking-star-solid.png";
import StatisticIcon6 from "../images/robot-solid.png";
import StatisticIcon7 from "../images/crown-solid.png";

interface GameStats {
  tournamentsWon: number;
  Wins: number;
  gamesPlayed: number;
  earningsPerTournament: number;
}

interface ProfileStats {
  totalTournamentsWon: number,
  totalGamesPlayed: number,
  totalTournamentPlayed: number,
  totalWagersPlayed: number,
}

interface UserData {
  id: string;
  gameStats: {
    valorant: GameStats;
    csgo: GameStats;
    callOfDuty: GameStats;
    leagueOfLegends: GameStats;
    apexLegends: GameStats;
  };
}

const fetchUserData = async (userId: string): Promise<UserData> => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return mockData.user;
};

const mockData: { user: UserData } = {
  user: {
    id: "1",
    gameStats: {
      valorant: {
        tournamentsWon: 15,
        Wins: 18,
        gamesPlayed: 25,
        earningsPerTournament: 50,
      },
      csgo: {
        tournamentsWon: 20,
        Wins: 22,
        gamesPlayed: 30,
        earningsPerTournament: 70,
      },
      callOfDuty: {
        tournamentsWon: 10,
        Wins: 15,
        gamesPlayed: 20,
        earningsPerTournament: 40,
      },
      leagueOfLegends: {
        tournamentsWon: 12,
        Wins: 20,
        gamesPlayed: 28,
        earningsPerTournament: 60,
      },
      apexLegends: {
        tournamentsWon: 18,
        Wins: 25,
        gamesPlayed: 35,
        earningsPerTournament: 80,
      },
    }
  },
};

const ProfileStatisticsComponent: React.FC<ProfileStats> = ({
  totalTournamentsWon,
  totalGamesPlayed,
  totalTournamentPlayed,
  totalWagersPlayed,
}) => {
  const theme = useTheme();
  const userId = "1";
  const [userData, setUserData] = useState<UserData | null>(null);
  const [selectedTab, setSelectedTab] =
    useState<keyof UserData["gameStats"]>("valorant");

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchUserData(userId);
      setUserData(data);
    };

    fetchData();
  }, []);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: keyof UserData["gameStats"]
  ) => {
    setSelectedTab(newValue);
  };

  const gameStats = userData.gameStats[selectedTab];
  const gameStatsArray = [
    {
      title: "Tournaments Won",
      value: gameStats.tournamentsWon,
      icon: StatisticIcon1,
    },
    {
      title: "Games Played",
      value: gameStats.gamesPlayed,
      icon: StatisticIcon3,
    },
    {
      title: "Wins",
      value: gameStats.Wins,
      icon: StatisticIcon7,
    },
    {
      title: "Earnings",
      value: gameStats.earningsPerTournament,
      icon: StatisticIcon4,
    },
  ];

  const myStatsArray = [
    {
      title: "Total Tournaments Won",
      value: totalTournamentsWon,
      icon: StatisticIcon1,
    },
    {
      title: "Total Games Played",
      value: totalGamesPlayed,
      icon: StatisticIcon3,
    },
    {
      title: " Total Tournaments Played",
      value: totalTournamentPlayed,
      icon: StatisticIcon6,
    },
    {
      title: "Total Wagers Played",
      value: totalWagersPlayed,
      icon: StatisticIcon5,
    },
  ];

  let gridSpacing;
  if (isSmallScreen) {
    gridSpacing = 3; // Spacing for small screens
  } else if (isMediumScreen) {
    gridSpacing = 7; // Spacing for medium screens
  } else if (isLargeScreen) {
    gridSpacing = 0; // Spacing for large screens
  }

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: "white",
        borderRadius: 4,
      }}
    >
      <CardContent>
        <Typography variant="h2" sx={{mb: 1}}>GAME STATISTICS</Typography>
        <Typography>Player's game specific statistics</Typography>
        <Box sx={{ mb: 3, borderBottom: 1, borderColor: "#00E8E8" }}>
        <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        textColor="inherit"
        indicatorColor="secondary"
        variant={isSmallScreen ? "scrollable" : "standard"}
        scrollButtons={isSmallScreen ? "auto" : undefined}
        allowScrollButtonsMobile
        sx={{
          
          "& .MuiTab-root": {
            minWidth: isSmallScreen ? "auto" : undefined,
            px: isSmallScreen ? 1 : 0,
            py: isSmallScreen ? 1 : 0,
            mr: isSmallScreen ? 2 : 4,
            fontWeight: "bold",
          },
          "& .Mui-selected": {
            color: "#00E8E8",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#00E8E8",
            height: "2px",
          },
        }}
      >
        <Tab label="Valorant" value="valorant" disableRipple />
        <Tab label="CS:GO" value="csgo" disableRipple />
        <Tab label="Call of Duty" value="callOfDuty" disableRipple />
        <Tab label="League of Legends" value="leagueOfLegends" disableRipple />
        <Tab label="Apex Legends" value="apexLegends" disableRipple />
      </Tabs>
    </Box>
        <Grid
          container
          spacing={gridSpacing}
          justifyContent="space-between"
          alignItems="center"
        >
          {gameStatsArray.map((stat, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <ProfileStatCard
                title={stat.title}
                value={stat.value}
                icon={stat.icon}
              />
            </Grid>
          ))}
        </Grid>

        <Typography variant="h2" sx={{ mt: 3, mb: 1}}>
          MY STATISTICS
        </Typography>
        <Typography>My Current progress</Typography>
        <Box sx={{ mt: 1.5,  borderBottom: 1, borderColor: "#00E8E8" }}>
          </Box>
        <Grid
          container
          spacing={gridSpacing}
          justifyContent="space-between"
          alignItems="center"
        >
          {myStatsArray.map((stat, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              sx={{ display: "flex", justifyContent: "center", mt: 3 }}
            >
              <ProfileStatCard
                title={stat.title}
                value={stat.value}
                icon={stat.icon}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProfileStatisticsComponent;
