import * as React from 'react';
import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TournamentInfo from '../components/TournamentInfo'; 

import Participants from '../components/Participants'
import Prizes from '../components/Prizes'
import Matches from '../components/MatchHistory'
import BracketProp from './BracketProp';

interface SimpleTabsProps {
  tournamentData: any;
}

export default function SimpleTabs({ tournamentData }: SimpleTabsProps) {
  const [value, setValue] = React.useState('overview');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  console.log(tournamentData);
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{marginBottom: '2em', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab value="overview" label="OVERVIEW" sx={{ minWidth: 'auto' }} />
          <Tab value="bracket" label="BRACKET" sx={{ minWidth: 'auto' }} />
          <Tab value="matches" label="MATCHES" sx={{ minWidth: 'auto' }} />
        </Tabs>
        <Tabs value={value} onChange={handleChange}>
          <Tab value="participants" label="PARTICIPANTS" sx={{ minWidth: 'auto' }} />
          <Tab value="prizes" label="PRIZES" sx={{ minWidth: 'auto' }} />
        </Tabs>
      </Box>
      {value === 'overview' && tournamentData &&  (
        <Box>
          <TournamentInfo tournamentData={tournamentData}/>
        </Box>
      )} 
      {value === 'participants' && (
        <Box>
          <Participants tournamentData={tournamentData}/>
        </Box>
      )} 
      {value === 'prizes' && (
        <Box>
          <Prizes tournamentData={tournamentData}/>
        </Box>
      )} 
      {value === 'matches' && (
        <Box>
          <Matches />
        </Box>
      )}
      {value === 'bracket' && (
        <Box>
          <BracketProp />
        </Box>
      )}  
    </Box>
  );
}
