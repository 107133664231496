import React from 'react';
import {Box, Typography} from '@mui/material';
import EastIcon from '@mui/icons-material/East';

interface BannerDetails {
    title: string;
    pages?: string[];
}
function Banner({title, pages}: BannerDetails) {
  return (
    <div>
        <Box mb="35px">
            <Typography variant='h1' fontFamily={"Orbitron, Arial, sans-serif"} textAlign="center" textTransform="uppercase" fontSize={{xs:"40", md:"54px", lg:"76px"}} lineHeight="91px" mb="10px">
                {title}
            </Typography>
            <Typography textAlign="center" fontFamily={"Orbitron, Arial, sans-serif"}> Home <EastIcon/>  Tournaments </Typography>
        </Box>
      
    </div>
  )
}

export default Banner
