import React from "react";
import Typography from "@mui/material/Typography";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { Box } from "@mui/material";

const Cart = () => {
  const auth = useAuth();
  return (
    <Box display="flex" flexDirection="column" sx={{py: 5}}>
      <Typography variant="h4">Cart</Typography>
      <div>Hello {auth.user?.profile.sub}</div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget ipsum
        velit. Nullam id urna id purus scelerisque sollicitudin. Integer id nisi
        sed metus placerat dapibus ac eget eros. Donec viverra velit nec enim
        convallis, sit amet hendrerit justo lobortis. Integer et diam sapien.
        Sed varius tristique quam ac posuere. Nam quis nisi condimentum, aliquam
        libero id, consectetur magna. Integer interdum consequat urna at
        lacinia.
      </p>
      </Box>
  );
};
const WithAuthenticationRequiredProps = {
  onRedirecting: (): React.JSX.Element => (
    <div>Redirecting to the login page...</div>
  ),
};

export default withAuthenticationRequired(
  Cart,
  // @ts-ignore
  WithAuthenticationRequiredProps,
);
