import React from "react";
import { Card, Typography, Box, useTheme } from "@mui/material";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import SellOutlined from "@mui/icons-material/SellOutlined";
import ProfileEarningsCard from "./ProfileEarningsCard";

interface ProfileEarningsComponentProps {
  wagerMoneyEarned: number;
  tournamentMoneyEarned: number;
  totalMoneyEarned: number;
}

const ProfileEarningsComponent: React.FC<ProfileEarningsComponentProps> = ({ 
  wagerMoneyEarned,
  tournamentMoneyEarned,
  totalMoneyEarned,
}) => {
  const theme = useTheme();
  const earningStats = [
    {
      title: "Wager Money Earned",
      icon: <SellOutlined fontSize="inherit" />,
      value: wagerMoneyEarned,
      iconBgColor: 'orange'
    },
    {
      title: "Tournament Money Earned",
      icon: <EmojiEventsOutlinedIcon fontSize="inherit" />,
      value: tournamentMoneyEarned,
      iconBgColor: 'green'
    },
    {
      title: "Total Money Earned",
      icon: <SavingsOutlinedIcon fontSize="inherit" />,
      value: totalMoneyEarned,
      iconBgColor: theme.palette.primary.dark
    },
  ];

  return (
    <Card sx={{ backgroundColor: theme.palette.primary.main, p: 2, color: 'white', borderRadius: 4 }}>
      <Typography variant="h3">EARNINGS</Typography>
      <Typography>My current earnings</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        {earningStats.map((stat, index) => (
          <ProfileEarningsCard
            key={index}
            title={stat.title}
            value={stat.value}
            icon={stat.icon}
            iconBgColor={stat.iconBgColor}
          />
        ))}
      </Box>
    </Card>
  );
};

export default ProfileEarningsComponent;
