import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import StatisticIcon from "../images/statistics-icon-4.png";

interface ProfileEarningsCardProps {
  title: string;
  value: number;
  icon: React.ReactNode;
  iconBgColor: string;
}

const ProfileEarningsCard: React.FC<ProfileEarningsCardProps> = ({
  title,
  value,
  icon,
  iconBgColor,
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        mb: 2,
        backgroundColor: theme.palette.primary.light,
        border: "none",
        color: "white",
        p: 1,
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          height: 60,
          width: 60,
          backgroundColor: iconBgColor,
          borderRadius: "50%",
          mr: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 30,
        }}
      >
        {icon}
      </Box>
      <Box sx={{display: "flex", flexDirection: 'column'}}>

      <Box sx={{display: 'flex',  alignItems: 'center'}}>
          <img
            src={StatisticIcon}
            style={{ height: "20px", width: "20px", marginRight: 2 }}
            alt="coins"
            />
        <Typography variant="h6">
          {value}
        </Typography>
      </Box>
        <Typography sx={{ fontSize: 14 }}>{title}</Typography>
            </Box>
    </Card>
  );
};

export default ProfileEarningsCard;
