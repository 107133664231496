import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

import battleImg from "../images/counter-icon-1.png";
import trophyImg from "../images/counter-icon-2.png";
import { theme } from "../utils/theme";

interface CounterProps {
  end: number;
  duration: number;
  color: string;
  text: string;
  textSize: string;
  textColor: string;
  imgSrc: string;
}

const Counter: React.FC<CounterProps> = (props) => {
  const { end, duration, text, color, textColor, imgSrc } = props;
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const theme = useTheme();

  return (
    <VisibilitySensor
      partialVisibility
      onChange={(isVisible: boolean) => {
        if (isVisible) {
          setViewPortEntered(true);
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "15rem",
          height: "17rem",
          fontSize: "2em",
          fontWeight: "bold",
          backgroundColor: "transparent",
          borderRadius: "1.2vw",
          mx: 6,
          my: {
            xs: 4, 
          },
          background:
            "linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgb(34, 40, 49))",
          border: "1px solid white",
        }}
      >
        <Box
          sx={{
            width: "6.5rem",
            height: "6.5rem",
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "0.4em",
            marginTop: "1.3em",
            bgcolor: theme.palette.primary.light,
            border: "0.6rem solid ",
            borderColor: theme.palette.primary.dark,
          }}
        >
          <img src={imgSrc} alt="" style={{ width: "60%", height: "60%" }} />
        </Box>
        {viewPortEntered ? (
          <Typography sx={{ color: color, fontSize: "1em" }}>
            {text === "WINNINGS AWARDED" ? "$" : ""}
            <CountUp end={end} duration={duration} />
          </Typography>
        ) : null}
        <Typography
          sx={{
            fontSize: "0.6em",
            textAlign: "center",
            color: textColor,
          }}
        >
          {text}
        </Typography>
      </Box>
    </VisibilitySensor>
  );
};

const CounterComponent: React.FC = () => {
  return (
    <Stack
      direction={{ sm: "column", md: "row" }}
      justifyContent="center"
      alignItems="center"
    >
      <Counter
        end={99999}
        duration={5}
        color={theme.palette.text.secondary}
        text="MATCHES PLAYED"
        textSize="0.2em"
        textColor={theme.palette.text.secondary}
        imgSrc={battleImg}
      />
      <Counter
        end={88888}
        duration={5}
        color={theme.palette.text.secondary}
        text="WINNINGS AWARDED"
        textSize="0.2em"
        textColor={theme.palette.text.secondary}
        imgSrc={trophyImg}
      />
    </Stack>
  );
};

export default CounterComponent;
