import "./App.css";
import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import ResponsiveAppBar from "./components/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Profile from "./pages/Profile";
import ShopDetails from "./pages/ShopDetails";
import Shop from "./pages/Shop";
import { AuthProvider } from "react-oidc-context";
import authConfig from "./authConfig";
import WebStartComponent from "./components/WebStartComponent";
import AvailableTournamentComponent from "./components/FeaturedTournament";
import WaitingRoom from "./pages/SampleChat";
import Wager from "./pages/Wager";
import Tournament from "./pages/Tournament";
import TournamentSingle from "./pages/TournamentSingle";
import Contact from "./pages/Contact";
import WagerCreation from "./pages/CreateWager";

import Team from "./pages/Team";
import TeamDashboard from "./pages/TeamDashboard";
import HowItWorksComponent from "./components/HowItWorksComponent";
import CounterComponent from "./components/WebStats";
import FeatureComponent from "./components/Features";
import AdminPage from "./pages/Admin";
import Footer from "./components/Footer";
import { theme } from "./utils/theme";
import { User } from "oidc-client-ts";
import SnackbarProvider from "./components/SnackbarProvider";
import { Provider } from "react-redux";
import { store } from "./store";

const onSigninCallback = async (_user: User | void): Promise<void> => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

function App() {
  return (
    <AuthProvider {...authConfig} onSigninCallback={onSigninCallback}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <SnackbarProvider>
              <CssBaseline />
              <ResponsiveAppBar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/profile/:id" element={<Profile />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/shop-details" element={<ShopDetails />} />
                <Route path="/hub" element={<WaitingRoom />} />
                <Route path="/wager" element={<Wager />} />
                <Route path="/tournament" element={<Tournament />} />
                <Route
                  path="/tournamentsingle/:tournamentId"
                  element={<TournamentSingle />}
                />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/team" element={<Team />} />
                <Route
                  path="/team-dashboard/:teamId"
                  element={<TeamDashboard />}
                />
                <Route path="/WagerCreation" element={<WagerCreation />} />
                <Route path="/" element={<Wager />} />
              </Routes>
              <Footer />
            </SnackbarProvider>
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

function Home() {
  return (
    <>
      <Container>
        <WebStartComponent />
        <AvailableTournamentComponent />
        <CounterComponent />
        <HowItWorksComponent />
        <FeatureComponent />
      </Container>
    </>
  );
}

export default App;
