import * as React from "react";
import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "react-oidc-context";
import { BASE_URL_API } from "../services/fetchData";

interface Member {
  firstName: string;
  lastName: string | null;
  playstationGamertag: string;
  xboxGamertag: string;
  steamGamertag: string;
  riotGamesGamertag: string;
  activisionGamertag: string;
  id: string;
}

interface TeamData {
  name: string;
  description: string;
  imageUrl: string;
  members: Member[];
  maxMembers: number;
  entryFee: number;
  adminId: string;
  admin: Member;
  id: string;
}

interface TeamDashboardProps {
  teamId: string;
}

const TeamLeaderDashboard = ({ teamId }: TeamDashboardProps) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [teamData, setTeamData] = useState<TeamData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const auth = useAuth();
  const idToken = auth.user?.id_token; // Get the ID token

  useEffect(() => {
    const fetchTeamData = async () => {
      setLoading(true);
      const response = await fetch(`${BASE_URL_API}/Teams/WithUsers/${teamId}`);
      const data = await response.json();
      setTeamData(data);
      setLoading(false);
    };

    const checkIfAdmin = async () => {
      const response = await fetch(`${BASE_URL_API}/Teams/${teamId}/isAdmin`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const data = await response.json();
      setIsAdmin(data);
    };

    fetchTeamData();
    checkIfAdmin();
  }, [teamId, idToken]);

  const handleDeleteMember = async (memberId: string) => {
    try {
      const response = await fetch(
        `${BASE_URL_API}/Teams/${teamData?.id}/remove-player/${memberId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      if (response.ok) {
        setTeamData((prevTeamData) => {
          if (!prevTeamData) return null;
          return {
            ...prevTeamData,
            members: prevTeamData.members.filter(
              (member) => member.id !== memberId,
            ),
          };
        });
      } else {
        console.error("Failed to delete member:", await response.text());
      }
    } catch (error) {
      console.error("Failed to delete member:", error);
    }
  };

  const handleDisbandTeam = async () => {
    try {
      const response = await fetch(
        `${BASE_URL_API}/api/Teams/${teamData?.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        },
      );
      if (response.ok) {
        setTeamData(null);
      } else {
        console.error("Failed to disband team:", await response.text());
      }
    } catch (error) {
      console.error("Failed to disband team:", error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!teamData) {
    return <div>No team data...</div>;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Team Leader Dashboard (Tournaments)
      </Typography>
      <Paper
        sx={{ p: 2, mb: 2, display: "flex", justifyContent: "space-evenly" }}
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            {teamData.name}
          </Typography>
          <Avatar
            src={teamData.imageUrl}
            alt="Team Logo"
            sx={{ width: 100, height: 100, mb: 2 }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h6">Matches</Typography>
            {/* Add match details here*/}
            <Box
              sx={{ mb: 2, p: 1, border: "1px solid black", borderRadius: 1 }}
            >
              <Typography>Match Time: 12:15pm</Typography>
              <Typography>Opponent: Synergy</Typography>
              <Button variant="contained">View Match Details</Button>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Team Roster
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Gamertag</TableCell>
                {isAdmin && <TableCell align="right">Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {teamData.members.map((member, index) => (
                <TableRow key={index}>
                  <TableCell>{`${member.firstName}${member.lastName ? ` ${member.lastName}` : ""}`}</TableCell>
                  <TableCell>
                    <div>PlayStation: {member.playstationGamertag}</div>
                    <div>Xbox: {member.xboxGamertag}</div>
                    <div>Steam: {member.steamGamertag}</div>
                    <div>Riot Games: {member.riotGamesGamertag}</div>
                    <div>Activision: {member.activisionGamertag}</div>
                  </TableCell>
                  {isAdmin && member.id !== teamData.adminId && (
                    <TableCell align="right">
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteMember(member.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isAdmin && (
          <Button
            variant="contained"
            color="error"
            sx={{ mt: 2 }}
            onClick={handleDisbandTeam}
          >
            Disband Team
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default TeamLeaderDashboard;
