// ExampleComponent.jsx

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

const WebStartComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleTournamentButtonClick = () => {
    navigate("/tournament");
    window.scrollTo(0, 0);
  };
  const handleWagerButtonClick = () => {
    navigate("/wager");
    window.scrollTo(0, 0);
  };

  return (
    <Box sx={{ my: 30 }}>
      <Typography
        variant="h1"
        align="center"
        sx={{
          marginBottom: 2,
          color: theme.palette.primary.dark,
          fontFamily: "Orbitron, Arial, sans-serif",
          fontSize: {
            xs: "3rem",
            sm: "3rem",
            md: "4rem",
            lg: "5rem",
          },
        }}
      >
        ESPORTIUM
      </Typography>
      <Typography
        variant="h2"
        align="center"
        sx={{ marginBottom: 2, fontFamily: "Orbitron, Arial, sans-serif" }}
      >
        CLAIM YOUR GLORY NOW
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{}}>
        Compete in Free and Paid entry Tournaments. Transform your games to real
        money eSports
      </Typography>
      <Box display="flex" justifyContent="center" sx={{m: 10}}>
        <Button
          onClick={handleTournamentButtonClick}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.primary.dark,
            width: 150,
            borderRadius: 1,
            p: 1,
            fontFamily: "Orbitron, Arial, sans-serif",
            mr: {
              xs: 2,
              sm: 4,
              md: 6,
              lg: 8
            }
          }}
        >
          TOURNAMENTS
        </Button>
        <Button
          onClick={handleWagerButtonClick}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.primary.dark,
            width: 150,
            borderRadius: 1,
            p: 1,
            fontFamily: "Orbitron, Arial, sans-serif",
          }}
        >
          WAGER
        </Button>
      </Box>
    </Box>
  );
};

export default WebStartComponent;
