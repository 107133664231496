import { BASE_URL_API, fetchData, postData } from "./fetchData";

export const fetchUserId = async (authToken: string | undefined) => {
  try {
    var response = await fetchData("User/currentUserId", authToken);
    console.log(response);
    const userId = response;
    return userId;
  } catch (error) {
    console.error("Error fetching user ID:", error);
    return null;
  }
};

export const fetchUserBalance = async (authToken: string | undefined) => {
  try {
    var response = await fetchData("User/balance", authToken);
    const balance = response;
    return balance;
  } catch (error) {
    console.error("Error fetching user balance:", error);
    return null;
  }
};

export type GamerTagsPaylaod = {
  PlaystationGamertag: string | null;
  XboxGamertag: string | null;
  SteamGamertag: string | null;
  RiotGamesGamertag: string | null;
  ActivisionGamertag: string | null;
};

export const setGamerTags = async (
  authToken: string | undefined,
  payLoad: GamerTagsPaylaod,
) => {
  try {
    var response = await postData("User/gamertags", payLoad, authToken);
    console.log(response);

    return response.data;
  } catch (error) {
    console.error("Error updating tags:", error);
    return null;
  }
};

export const fetchUserData = async (
  id: string,
  authToken: string | undefined,
) => {
  try {
    const response = await fetch(`${BASE_URL_API}/User/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user balance:", error);
    return null;
  }
};
