import React from "react";
import { Card, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material";

interface ProfileStatCardProps {
  title: string;
  value: number;
  icon: string;
}

const ProfileStatCard: React.FC<ProfileStatCardProps> = ({
  title,
  value,
  icon,
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: {xs: '100%', md: 140},
        height: 225,
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        border: 'none',
        borderRadius: 4
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
          p: 1
        }}
      >
        <div style={{ width: '50px', height: '50px' }}>

          <img
            src={icon}
            alt={`${title} icon`}
            style={{filter: 'brightness(0) invert(1)',
              width: '100%',
              height: '100%', }}
              />
              </div>
          <Typography variant="h6" sx={{fontSize: '14px',}}>
            {title}
          </Typography>
        <Typography variant="h4">{value}</Typography>
      </Box>
    </Card>
  );
};

export default ProfileStatCard;
