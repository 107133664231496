import React from "react";
import Typography from "@mui/material/Typography";
import WebStats from "../components/WebStats";
import Features from "../components/Features";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";


const About = () => {
  return (
    <Box display="flex" flexDirection="column" sx={{px: 10, py: 5}}>
      <Typography variant="h4" align="center">
        About eSportium
      </Typography>
      <Typography variant="body1" align="center" gutterBottom sx={{ my: 5 }}>
        eSportium is a gaming platform hub offering Esports tournaments for the
        biggest titles on every device. eSportium provides an immersive online
        gaming experience for all Esports fans out there. Whether you're a new
        gamer, casual player, an amateur pro, or a registered professional, if
        you're passionate about playing and competing in the hottest
        tournaments, eSportium has what you need. Our team is made up of gamers,
        nerds, techies, and dreamers, who love what we do and are dedicated to
        bringing you the best in competitive gaming. Whether it’s local, GCC,
        MENA, or even global, we’ve got tournaments for everyone.
      </Typography>
      <Button
        sx={{ width: "140px", margin: "auto", backgroundColor: "#1976d2" }}
        variant="contained"
        >
        LEARN MORE
      </Button>
      <WebStats />
      <Features />
      </Box>
  );
};

export default About;
