import React from 'react';
import { Avatar, Box, IconButton, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const participants = [
  { id: 1, name: 'Admin 1', imageUrl: '/path/to/image1.png', header: 'Jean Reno', body: 'OTL Support' },
  { id: 2, name: 'Admin 2', imageUrl: '/path/to/image2.png', header: 'Leonardo Da Vinci', body: 'OTL Support' },
  { id: 3, name: 'Admin 3', imageUrl: '/path/to/image3.png', header: 'Christopher Colombus', body: 'OTL Support' },
];

const ParticipantsList: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedParticipant, setSelectedParticipant] = React.useState<number | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, participantId: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedParticipant(participantId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ borderRadius:'1em',width: '120%', marginX: 'auto', bgcolor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ width: '100%', bgcolor: 'transparent' }}>
        {participants.map((participant) => (
          <ListItem
            key={participant.id}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #e0e0e0', width: '100%' }}
          >
            <ListItemAvatar>
              <Avatar alt={participant.name} src={participant.imageUrl} />
            </ListItemAvatar>
            <Box sx={{ position:'absolute', paddingLeft:'3em' }}>
              <Typography variant="body1" component="div">
                {participant.header}
              </Typography>
              <Typography variant="subtitle2" component="div">
                {participant.body}
              </Typography>
            </Box>
            <IconButton onClick={(event) => handleClick(event, participant.id)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`participant-menu-${participant.id}`}
              anchorEl={anchorEl}
              open={Boolean(anchorEl && selectedParticipant === participant.id)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Share</MenuItem>
              <MenuItem onClick={handleClose}>View Profile</MenuItem>
            </Menu>
          </ListItem>
        ))}
      </Box>
    </Box>
  );
};

export default ParticipantsList;
