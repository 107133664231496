import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import WagerBar from "../components/WagerBar";
import { BASE_URL_API, fetchData } from "../services/fetchData";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { WithAuthenticationRequiredProps } from "./Checkout";
import { useAppSelector } from "../hooks";
import { fetchUserBalance, fetchUserData } from "../services/userService";
import { useDispatch } from "react-redux";
import { updateBalance, updateUserInfo } from "../reducers/userReducer";

type Image = {
  src: string;
  alt: string;
  description: string;
};

type GameInfo = {
  name: string;
  imageUrl: string;
  sizes: number[];
};

export type WagerType = {
  id: string;
  gameName: string;
  gamerTagPlayerA: string;
  gamerTagPlayerB: string;
  prizePool: string;
  rules: string | null;
  type: string;
  winlossdeclared: boolean;
  creator: string | null;
  remainingTimeToJoin: string;
  creatorId: string;
};

const Wager = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [clickedImage, setClickedImage] = useState<string | null>(null);
  const [games, setGames] = useState<GameInfo[]>([]);
  const [wagers, setWagers] = useState<WagerType[]>([]);
  const auth = useAuth();
  const userRed = useAppSelector((state) => state.user.value);
  const dispatch = useDispatch();

  //const [balance, setBalance] = useState<number>(0);
  // const userRed = useAppSelector((state) => state.user.value.userIfo);

  useEffect(() => {
    fetchUserBalance(auth.user?.id_token).then((balance) =>
      dispatch(updateBalance(balance)),
    );
  }, []);

  useEffect(() => {
    // Fetch games data from backend API
    axios
      .get(`${BASE_URL_API}/Games`)
      .then((response) => {
        setGames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching games:", error);
      });

    const data = fetchData("wager", auth.user?.id_token).then(
      (res: WagerType[]) => {
        setWagers(res);
      },
    );

    try {
      fetchUserData(userRed.userId.toString(), auth.user?.id_token)
        .then((data) => {
          dispatch(updateUserInfo(data));
        })
        .finally(() => {});
    } catch (e: any) {
      console.log(e);
    }
  }, []);

  const handleSelect = (image: Image) => {
    setSelectedImage(image);
  };

  const handleClick = (image: Image) => {
    setClickedImage(image.alt);
  };

  // Separate the special games
  const specialGames = ["EA FC", "Madden NFL", "UFC", "NHL"];
  const specialGamesList = games.filter((game) =>
    specialGames.includes(game.name),
  );
  const otherGamesList = games.filter(
    (game) => !specialGames.includes(game.name),
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <AppBar
        position="static"
        sx={{
          bgcolor: "primary",
          pt: "0.5em",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: isSmallScreen ? "9.5em" : isMediumScreen ? "10em" : "12em",
          overflow: "visible",
          clipPath: isSmallScreen
            ? "polygon(20% 60%, 80% 60%, 100% 0%, 0% 0%)"
            : "polygon(20% 100%, 80% 100%, 100% 0%, 0% 0%)", // Adjust clipPath based on screen size
        }}
      >
        <Box
          sx={{
            height: isSmallScreen ? "10em" : "14em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "2em",
            overflow: "visible",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-start",
              width: "70%",
              overflowX: isSmallScreen ? "scroll" : "auto",
              marginTop: isSmallScreen ? "-6em" : "0",
            }}
          >
            {otherGamesList.map((game, index) => (
              <Tooltip title={game.name} key={index}>
                <Box
                  component="img"
                  src={game.imageUrl}
                  alt={game.name}
                  loading="lazy"
                  onClick={() => {
                    handleSelect({
                      src: game.imageUrl,
                      alt: game.name,
                      description: game.name,
                    });
                    handleClick({
                      src: game.imageUrl,
                      alt: game.name,
                      description: game.name,
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.2)",
                    },
                    transform:
                      clickedImage === game.name ? "scale(1.2)" : "none",
                    width: isSmallScreen ? "3em" : "5em",
                    height: isSmallScreen ? "3em" : "5em",
                    objectFit: "cover",
                    borderRadius: "0.7em",
                    margin: "1em 1em",
                  }}
                />
              </Tooltip>
            ))}
            {/* Divider */}
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mx: 2, borderWidth: "2px" }}
            />
            {specialGamesList.map((game, index) => (
              <Tooltip title={game.name} key={index}>
                <Box
                  component="img"
                  src={game.imageUrl}
                  alt={game.name}
                  loading="lazy"
                  onClick={() => {
                    handleSelect({
                      src: game.imageUrl,
                      alt: game.name,
                      description: game.name,
                    });
                    handleClick({
                      src: game.imageUrl,
                      alt: game.name,
                      description: game.name,
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.2)",
                    },
                    transform:
                      clickedImage === game.name ? "scale(1.2)" : "none",
                    width: isSmallScreen ? "3em" : "5em",
                    height: isSmallScreen ? "3em" : "5em",
                    objectFit: "cover",
                    borderRadius: "0.7em",
                    margin: "1em 1em",
                  }}
                />
              </Tooltip>
            ))}
          </Box>
        </Box>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          padding: "2em",
        }}
      >
        <Typography
          variant={isSmallScreen ? "h6" : "h4"}
          gutterBottom
          sx={{ marginTop: "1em" }}
        >
          CREATE YOUR OWN WAGER
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/WagerCreation")}
          sx={{
            marginTop: "1em",
            width: isSmallScreen ? "6em" : "10em",
            fontSize: isSmallScreen ? "0.5em" : "1em",
            mb: "2em",
          }}
        >
          CREATE WAGER
        </Button>
        <Typography
          variant={isSmallScreen ? "h6" : "h4"}
          gutterBottom
          sx={{ marginTop: "1em" }}
        >
          BROWSE WAGERS
        </Typography>
        {games.map((game, index) =>
          clickedImage?.toUpperCase() === game.name.toUpperCase()
            ? game.sizes.map((size, index) => (
                <Box
                  key={index}
                  sx={{
                    marginBottom: index === [1, 2].length - 1 ? "3em" : "0",
                  }}
                >
                  <Typography variant="h6" align="center">
                    {`${size}v${size}`}
                  </Typography>
                  <WagerBar
                    wagers={wagers.filter(
                      (wager) =>
                        wager.gameName === game.name &&
                        wager.type.charAt(0) === size.toString(),
                    )}
                  />
                </Box>
              ))
            : null,
        )}
      </Box>
    </Box>
  );
};

export default withAuthenticationRequired(
  Wager,
  // @ts-ignore
  WithAuthenticationRequiredProps,
);
