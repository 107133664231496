import React, { useState } from "react";
import TournamentForm from "../components/TournamentForm";
import TournamentDetailForm from "../components/TournamentDetailForm";
import { Tournament } from "../utils/types/Tournament";
import { TournamentDetail } from "../utils/types/TournamentDetail";
import { Box, Button, TextField } from "@mui/material";
import { useAuth } from "react-oidc-context";
import { useSnackbar } from "./../components/SnackbarProvider";
import { BASE_URL_API } from "../services/fetchData";

const AdminPage: React.FC = () => {
  const [isUpdateForm, setIsUpdateForm] = useState<boolean>(false);
  const [isUpdateDetailForm, setIsUpdateDetailForm] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  //Join tournament
  const [tournamentId, setTournamentId] = useState("");
  const auth = useAuth();
  const handleJoinTournament = () => {
    const idToken = auth.user?.id_token; // Get the ID token
    fetch(`${BASE_URL_API}/Tournaments/Join/${tournamentId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Successfully joined the tournament!", "success");
        } else {
          showSnackbar("Failed to join the tournament.", "error");
        }
      })
      .catch((error) => {
        showSnackbar(
          "An error occurred while joining the tournament.",
          "error",
        );
      });
  };

  const handleToggleForm = () => {
    setIsUpdateForm((prev) => !prev);
  };
  const handleToggleDetailForm = () => {
    setIsUpdateDetailForm((prev) => !prev);
  };

  const handleAddTournament = async (tournament: Tournament) => {
    try {
      // Convert startDate to ISO format and Drop the id since Id will be generated
      const isoStartDate = new Date(tournament.startDate).toISOString();
      tournament.startDate = isoStartDate;

      const { id, gameName, ...tournamentWithoutId } = tournament;

      const response = await fetch(`${BASE_URL_API}/Tournaments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tournamentWithoutId),
      });
      if (response.ok) {
        const data = await response.json();
        showSnackbar(
          `Tournament added successfully. Generated ID: ${data.id}`,
          "success",
        );
      } else {
        showSnackbar(
          `Failed to add tournament: ${response.statusText}`,
          "error",
        );
      }
    } catch (error) {
      showSnackbar(`Error updating tournament: ${error}`, "error");
    }
  };

  const handleUpdateTournament = async (
    tournament: Tournament,
    TournamentId?: string,
  ) => {
    try {
      // Convert startDate to ISO format and Drop the id since Id will be used in the API url
      const isoStartDate = new Date(tournament.startDate).toISOString();
      tournament.startDate = isoStartDate;
      const { id, ...tournamentWithoutId } = tournament;

      const response = await fetch(
        `${BASE_URL_API}/Tournaments/${TournamentId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tournamentWithoutId),
        },
      );
      if (response.ok) {
        showSnackbar("Tournament updated successfully", "success");
      } else {
        showSnackbar(
          `Failed to update tournament: ${response.statusText}`,
          "error",
        );
      }
    } catch (error) {
      showSnackbar(`Error updating tournament: ${error}`, "error");
    }
  };

  const handleAddTournamentDetail = async (
    tournamentDetail: TournamentDetail,
  ) => {
    try {
      // Drop the id since Id will be generated
      const { id, ...tournamentDetailWithoutId } = tournamentDetail;

      // Check if tournamentId exists
      const tournamentIdResponse = await fetch(
        `${BASE_URL_API}/Tournaments/${tournamentDetailWithoutId.TournamentId}`,
      );
      if (!tournamentIdResponse.ok) {
        showSnackbar(
          `Tournament ID: ${tournamentDetailWithoutId.TournamentId} does not exist!`,
          "error",
        );
        return;
      }
      console.log(JSON.stringify(tournamentDetailWithoutId));
      const response = await fetch(`${BASE_URL_API}/TournamentDetail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tournamentDetailWithoutId),
      });
      if (response.ok) {
        const data = await response.json();
        showSnackbar(
          `Tournament detail added successfully. Generated ID: ${data.id}`,
          "success",
        );
      } else {
        showSnackbar(
          `Failed to add tournament detail: ${response.statusText}`,
          "error",
        );
      }
    } catch (error) {
      showSnackbar(`Error adding tournament detail: ${error}`, "error");
    }
  };

  const handleUpdateTournamentDetail = async (
    tournamentDetail: TournamentDetail,
    TournamentDetailId?: string,
  ) => {
    try {
      // Drop the id since Id will be used in the API url
      const { id, ...tournamentDetailWithoutId } = tournamentDetail;

      // Check if tournamentId exists
      const tournamentIdResponse = await fetch(
        `${BASE_URL_API}/Tournaments/${tournamentDetailWithoutId.TournamentId}`,
      );
      if (!tournamentIdResponse.ok) {
        console.error(
          "Tournament Id does not exist:",
          tournamentDetailWithoutId.TournamentId,
        );
        return;
      }

      const response = await fetch(
        `${BASE_URL_API}/TournamentDetail/${TournamentDetailId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tournamentDetailWithoutId),
        },
      );
      if (response.ok) {
        showSnackbar("Tournament detail updated successfully", "success");
      } else {
        showSnackbar(
          `Failed to update tournament detail: ${response.statusText}`,
          "error",
        );
      }
    } catch (error) {
      showSnackbar(`Error updating tournament detail: ${error}`, "error");
    }
  };

  return (
    <Box sx={{ pl: 2 }}>
      <br />
      <Button variant="contained" color="primary" onClick={handleToggleForm}>
        {isUpdateForm ? "Add Tournament" : "Update Tournament"}
      </Button>
      <br />
      <br />
      {isUpdateForm ? (
        <TournamentForm onSubmit={handleUpdateTournament} isUpdate />
      ) : (
        <TournamentForm onSubmit={handleAddTournament} />
      )}

      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={handleToggleDetailForm}
      >
        {isUpdateDetailForm
          ? "Add Tournament Detail"
          : "Update Tournament Detail"}
      </Button>
      <br />
      <br />
      {isUpdateDetailForm ? (
        <TournamentDetailForm
          onSubmit={handleUpdateTournamentDetail}
          isUpdate
        />
      ) : (
        <TournamentDetailForm onSubmit={handleAddTournamentDetail} />
      )}
      <br />
      <br />
      <TextField
        label="Tournament ID"
        value={tournamentId}
        color={"secondary"}
        onChange={(e) => setTournamentId(e.target.value)}
      />
      <br />
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={handleJoinTournament}
      >
        Join Tournament
      </Button>
    </Box>
  );
};

export default AdminPage;
