import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';

//dummydata
const matches = [
  {
    date: '2024-05-01',
    time: '12:30 PM',
    player1: {
      rank: 'Rank II',
      name: 'Name 1',
    },
    score: '2:1',
    player2: {
      rank: 'Rank I',
      name: 'Name 2',
    },
    winner: 'player1',
    concluded: true,

  },
  {
    date: '2024-05-01',
    time: '3:45 PM',
    player1: {
      rank: 'Rank III',
      name: 'Name 3',
    },
    score: '1:3',
    player2: {
      rank: 'Rank I',
      name: 'Name 4',
    },
    winner: 'player2',
    concluded: true,

  },
  {
    date: '2024-05-02',
    time: '6:00 PM',
    player1: {
      rank: 'Rank I',
      name: 'Name 5',
    },
    score: '3:0',
    player2: {
      rank: 'Rank II',
      name: 'Name 6',
    },
    winner: 'player1',
    concluded: false,

  },
  
];

export default function CenteredRectangle() {
  const matchesByDate = matches.reduce((acc: {[key: string]: typeof matches}, match) => {
    (acc[match.date] = acc[match.date] || []).push(match);
    return acc;
  }, {});

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const boxStyle = isSmallScreen ? {
    height: '3em',
    width: '23em',
  } : {
    height: '7em',
    width: '80em',
  };

  const avatarSize = isSmallScreen ? {
    width: '0.7em',
    height: '0.7em',
  } : {
    width: '2em',
    height: '2em',
  };

  const smallFontSize = isSmallScreen ? '0.3em' : '0.7em';
  const fontSize = isSmallScreen ? '0.43em' : '1em';
  const bigFontSize = isSmallScreen ? '0.7em' : '1.8em';
  const datePadding = isSmallScreen ? '1%' : '16.5%';

  return (
    <Box
      sx={{
        marginTop: '0',
        display: 'flex',
        justifyContent: 'flex-start', 
        flexDirection: 'column', 
        alignItems: 'center',
      }}
    >
      {Object.entries(matchesByDate).map(([date, matches], index) => (
        <React.Fragment key={index} >
          <Typography variant="h4" sx={{ marginTop: 0, fontSize: bigFontSize,paddingLeft: datePadding, alignSelf: 'flex-start' }}>
            {date}
          </Typography>

          {matches.map((match, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'transparent',
                borderTop: '1px solid grey',
                borderBottom: index === matches.length - 1 ? '1px solid grey' : 'none',
                marginBottom: index === matches.length - 1 ? '2em' : '0', 
                ...boxStyle,
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '5%',
                  transform: 'translate(-50%, -50%)',
                  fontSize,
                }}
              >
                {match.time}
              </Typography>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: 'calc(50% - 3vw)', 
                  transform: 'translate(-100%, -50%)', 
                  display: 'flex',
                  flexDirection: 'row', 
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize, marginRight: '0.5em' }}>{match.player1.name}</Typography> 
                <Box sx={{ position: 'relative' }}>
                  {match.winner === 'player1' && (
                    <Typography
                      sx={{
                        position: 'absolute',
                        top: '-135%', 
                        color: 'green',
                        fontSize: smallFontSize,
                      }}
                    >
                      VICTORY
                    </Typography>
                  )}
                  <Typography sx={{ 
                    textAlign: 'center',
                    fontSize: smallFontSize, 
                    width: '4em',
                    color: '#0ABAB5', 
                    borderRadius: '16px', 
                    border: `0.5px solid #0ABAB5`,
                    marginRight: '0.5em' 
                  }}>
                    {match.player1.rank}
                  </Typography> 
                </Box>
                <Avatar sx={avatarSize} /> 
              </Box>
              <Typography
                variant="h3"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: bigFontSize,
                }}
              >
                {match.score}
              </Typography>
              {match.concluded && (
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '25%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#FFFFFF', 
                    fontSize: smallFontSize,
                    backgroundColor: '#008000', 
                    borderRadius: '16px', 
                    border: `0.5px solid #008000`, 
                    textAlign: 'center', 
                    width: '5em', 
                  }}
                >
                  Finished
                </Typography>
              )}
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 'calc(50% - 3vw)', 
                  transform: 'translate(100%, -50%)', 
                  display: 'flex',
                  flexDirection: 'row-reverse', 
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize, marginLeft: '0.5em' }}>{match.player2.name}</Typography>
                <Box sx={{ position: 'relative' }}>
                  {match.winner === 'player2' && (
                    <Typography
                      sx={{
                        position: 'absolute',
                        top: '-135%',
                        color: 'green',
                        fontSize: smallFontSize,
                      }}
                    >
                      VICTORY
                    </Typography>
                  )}
                  <Typography sx={{ 
                    textAlign: 'center',
                    fontSize: smallFontSize, 
                    width: '4em',
                    color: '#0ABAB5', 
                    borderRadius: '16px', 
                    border: `0.5px solid #0ABAB5` 
                  }}>
                    {match.player2.rank}
                  </Typography>
 
                </Box>
                <Avatar sx={{ ...avatarSize, marginRight: '0.5em' }} />
              </Box>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '5%',
                  transform: 'translate(50%, -50%)',
                  fontSize,
                }}
              >
                {'>'}
              </Typography>
            </Box>
          ))}
        </React.Fragment>
      ))}
    </Box>
  );
}
