import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { fetchWagerHistory } from "../services/wagerService";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

export type WagerHistoryType = {
  wagerId: string;
  gameName: string;
  type: string;
  playerTag: string;
  opponentTag: string;
  prizePool: number;
  result: string;
  createdAt: string;
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const WagerHistory = () => {
  const [history, setHistory] = useState<WagerHistoryType[]>([]);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchWagerHistory(auth.user?.id_token).then((items) => setHistory(items));
  }, []);

  return (
    <Box marginY={"50px"}>
      {history.length > 0 ? (
        <>
          <Typography
            marginY={"20px"}
            variant="h4"
            component="h1"
            color={"wheat"}
            gutterBottom
          >
            Wager History
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> Date</StyledTableCell>
                  <StyledTableCell align="right">Game Name</StyledTableCell>
                  <StyledTableCell align="right">Type</StyledTableCell>
                  <StyledTableCell align="right">Prize Pool</StyledTableCell>
                  <StyledTableCell align="right">Result</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((row) => (
                  <StyledTableRow key={row.gameName}>
                    <StyledTableCell component="th" scope="row">
                      {new Date(row.createdAt).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.gameName}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.type}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.prizePool}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.result}{" "}
                      {row.result == "in progress" && (
                        <Button
                          variant={"contained"}
                          onClick={() => {
                            try {
                              navigate("/hub", {
                                state: {
                                  wager: row.wagerId,
                                  user: row.playerTag,
                                },
                              });
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        >
                          Join
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default WagerHistory;
