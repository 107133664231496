import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Tournament } from "../utils/types/Tournament";
import { uploadImage } from "../utils/AwsUtils";
import { useAuth } from "react-oidc-context";
import { useSnackbar } from "./SnackbarProvider";
import { BASE_URL_API } from "../services/fetchData";

interface TournamentFormProps {
  onSubmit: (tournament: Tournament, id?: string) => void;
  isUpdate?: boolean;
}

const initialFormData: Tournament = {
  name: "",
  imageUrl: "",
  startDate: "",
  prizePool: 0,
  entryFee: 0,
  teamSize: "",
  maxTeamCount: 0,
  registeredTeamCount: 0,
  skillLevel: "",
  gameName: "",
  gameId: "",
  id: "",
};

const TournamentForm: React.FC<TournamentFormProps> = ({
  onSubmit,
  isUpdate = false,
}) => {
  const [formData, setFormData] = useState<Tournament>(initialFormData);
  const [tournamentId, setTournamentId] = useState<string | undefined>(
    undefined,
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;
    setTournamentId(id);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    if (selectedFile && auth.isAuthenticated) {
      try {
        const idToken = auth.user?.id_token; // Get the ID token
        if (idToken) {
          const gamesResponse = await fetch(
            `${BASE_URL_API}/Games/authorized`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            },
          );
          const games = await gamesResponse.json();
          const existingGame = games.find(
            (game: any) => game.name === formData.gameName,
          );

          if (existingGame === undefined) {
            showSnackbar(
              `Game '${formData.gameName}' does not exist.`,
              "error",
            );
            return;
          }

          // Use the existing game's ID
          formData.gameId = existingGame.id;

          // Upload the image
          const uploadResponse = await uploadImage(
            selectedFile,
            idToken,
            "Tournaments",
          );
          formData.imageUrl = uploadResponse.path;
        } else {
          console.error("ID token is missing");
        }
      } catch (error) {
        console.error("Image upload failed:", error);
        return;
      }
    }
    onSubmit(formData, isUpdate ? tournamentId : undefined);
    setFormData(initialFormData);
    setTournamentId(undefined);
  };

  return (
    <form>
      <Grid container spacing={2} sx={{ pl: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {isUpdate ? "Update Tournament" : "Add Tournament"}
          </Typography>
        </Grid>
        {isUpdate && (
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Tournament ID"
              name="tournamentId"
              value={tournamentId || ""}
              onChange={handleIdChange}
              sx={{ input: { color: "white" } }}
            />
          </Grid>
        )}
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            sx={{ input: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={8}>
          <input accept="image/*" type="file" onChange={handleFileChange} />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label=""
            name="startDate"
            type="datetime-local"
            value={formData.startDate}
            onChange={handleChange}
            sx={{ input: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Prize Pool"
            name="prizePool"
            type="number"
            value={formData.prizePool}
            onChange={handleChange}
            sx={{ input: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Entry Fee"
            name="entryFee"
            type="number"
            value={formData.entryFee}
            onChange={handleChange}
            sx={{ input: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Team Size"
            name="teamSize"
            value={formData.teamSize}
            onChange={handleChange}
            sx={{ input: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Max Team Count"
            name="maxTeamCount"
            type="number"
            value={formData.maxTeamCount}
            onChange={handleChange}
            sx={{ input: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Registered Team Count"
            name="registeredTeamCount"
            type="number"
            value={formData.registeredTeamCount}
            onChange={handleChange}
            sx={{ input: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Skill Level"
            name="skillLevel"
            value={formData.skillLevel}
            onChange={handleChange}
            sx={{ input: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Game Name"
            name="gameName"
            value={formData.gameName}
            onChange={handleChange}
            sx={{ input: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={8}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {isUpdate ? "Update" : "Add"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default TournamentForm;
