import { Box } from "@mui/material";
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { BASE_URL_API } from "../services/fetchData";
import { useAuth } from "react-oidc-context"; // This value is from the props in the UI

// This value is from the props in the UI
const style = { layout: "vertical" };

const createOrder = async (token: string | undefined) => {
  try {
    const response = await fetch(`${BASE_URL_API}/Payment/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // // use the "body" param to optionally pass additional order information
      // // like product ids and quantities
      // body: JSON.stringify({
      //   cart: [
      //     {
      //       id: "YOUR_PRODUCT_ID",
      //       quantity: "YOUR_PRODUCT_QUANTITY",
      //     },
      //   ],
      // }),
    });

    const orderData = await response.json();
    console.log(orderData);

    if (orderData.id) {
      return orderData.id;
    } else {
      const errorDetail = orderData?.details?.[0];
      const errorMessage = errorDetail
        ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
        : JSON.stringify(orderData);

      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error(error);
    // setMessage(
    //     `Could not initiate PayPal Checkout...${error}`
    // );
  }
};
const onApprove = async (
  data: any,
  actions: any,
  token: string | undefined,
) => {
  try {
    const response = await fetch(
      `${BASE_URL_API}/Payment/orders/${data.orderID}/capture`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const orderData = await response.json();
    // Three cases to handle:
    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
    //   (2) Other non-recoverable errors -> Show a failure message
    //   (3) Successful transaction -> Show confirmation or thank you message

    const errorDetail = orderData?.details?.[0];

    if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
      // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
      return actions.restart();
    } else if (errorDetail) {
      // (2) Other non-recoverable errors -> Show a failure message
      throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
    } else {
      // (3) Successful transaction -> Show confirmation or thank you message
      // Or go to another URL:  actions.redirect('thank_you.html');
      console.log(orderData);
      const transaction = orderData.purchaseUnits[0].payments.captures[0];
      // setMessage(
      //     `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
      // );
      console.log(
        `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
      );
      console.log(
        "Capture result",
        orderData,
        JSON.stringify(orderData, null, 2),
      );
    }
  } catch (error) {
    console.error(error);
    // setMessage(
    //     `Sorry, your transaction could not be processed...${error}`
    // );
    console.log(`Sorry, your transaction could not be processed...${error}`);
  }
};

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({
  showSpinner,
  token,
}: {
  showSpinner: boolean;
  token: string | undefined;
}) => {
  const [{ isPending }] = usePayPalScriptReducer();

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        disabled={false}
        forceReRender={[style]}
        fundingSource={undefined}
        createOrder={() => createOrder(token)}
        onApprove={(data: any, actions: any) => onApprove(data, actions, token)}
      />
    </>
  );
};
const PayPalButton: React.FC = () => {
  const auth = useAuth();
  return (
    <Box sx={{ width: "75%" }}>
      <PayPalScriptProvider
        options={{
          clientId:
            "AYvNNShrjGLZuOzFxyphyZR0mJfy2KgwZbNYRALz7vGj-XBdXBPBs5JupU4ymq8fYKJJrpHrCJp1E9Ja",
          components: "buttons",
          currency: "CAD",
        }}
      >
        <ButtonWrapper showSpinner={false} token={auth.user?.id_token} />
      </PayPalScriptProvider>
    </Box>
  );
};

export default PayPalButton;
