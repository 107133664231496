import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import Checkmark from "../images/checkmark.png";
import Friend from "../images/user-icon.png";

interface Participant {
  id: number;
  firstName: string;
  lastName: string;
  picture: string;
  email: string;
  isOnline: boolean;
  isVerified: boolean;
  profileViews: number;
}

const ProfileBanner: React.FC<Participant> = ({
  id,
  firstName,
  lastName,
  picture,
  isOnline,
  isVerified,
  profileViews,
}) => {
  return (
    <Box
      key={id}
      margin="auto"
      bgcolor={"wheat"}
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems="center"
      p={2}
      width={{ xs: "100%", sm: "75%", md: "45%" }}
      height={{ xs: 200, sm: 150, md: 200 }}
      borderRadius="2em"
      border="2px solid white"
    >
      <Box display="flex" alignItems="center" position="relative">
        <Avatar
          src={picture}
          sx={{
            width: "5em",
            height: "5em",
            border: "4px solid transparent",
            boxShadow: "0 0 0 2px #ADD8E6",
          }}
        />
        <Box
          position="absolute"
          top={5}
          left={78}
          width={20}
          height={20}
          bgcolor={isOnline ? green[500] : red[500]}
          borderRadius="50%"
          border="4px solid white"
        />
        <Box ml={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6">
              {firstName} {lastName}
            </Typography>
            {isVerified && (
              <Avatar src={Checkmark} sx={{ width: 16, height: 16, ml: 1 }} />
            )}
          </Box>
          <Typography variant="body2">{profileViews} profile views</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Button
          sx={{ borderRadius: "3em", width: "8em", height: "3em" }}
          variant="contained"
        >
          MESSAGE
        </Button>
        <Box
          sx={{
            margin: "1em",
            width: "2em",
            height: "2em",
            borderRadius: "50%",
            bgcolor: "rgba(128, 128, 128, 0.5)",
            backgroundImage: `url(${Friend})`,
            backgroundSize: "50%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      </Box>
    </Box>
  );
};

export default ProfileBanner;
