import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import axios from "axios";
import { useSnackbar } from "../components/SnackbarProvider";
import Arrow from "../images/arrow.png";
import { BASE_URL_API } from "../services/fetchData";
import { useAppSelector } from "../hooks";

interface GameInfo {
  name: string;
  sizes: number[];
}

interface Rule {
  id: string;
  description: string;
}

export interface Gamertags {
  playstationGamertag: string;
  xboxGamertag: string;
  steamGamertag: string;
  riotGamesGamertag: string;
  activisionGamertag: string;
}

interface Team {
  id: string;
  name: string;
  maxMembers: number;
}

const WagerCreation = () => {
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const [creditAmount, setCreditAmount] = useState("");
  const [game, setGame] = useState<string>(""); // Initialize with empty string
  const [size, setSize] = useState<string>(""); // Initialize with empty string
  const [games, setGames] = useState<GameInfo[]>([]); // State for storing games data
  const [rules, setRules] = useState<Rule[]>([]); // State for storing rules data
  const [selectedRules, setSelectedRules] = useState<string[]>([]);
  const [gamertags, setGamertags] = useState<Gamertags | null>(null);
  const [selectedGamertag, setSelectedGamertag] = useState<string>("");
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const [selectedTeamMaxMembers, setSelectedTeamMaxMembers] =
    useState<number>(0);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch games data from backend API
    axios
      .get<GameInfo[]>(`${BASE_URL_API}/Games`)
      .then((response) => {
        setGames(response.data); // Store fetched games data in state
        // Set the first game as the initial value
        if (response.data.length > 0) {
          setGame(response.data[0].name);
          setSize(response.data[0].sizes[0].toString()); // Set size to first option of the first game
        }
      })
      .catch((error) => {
        console.error("Error fetching games:", error);
      });

    // Fetch rules data from backend API
    axios
      .get<Rule[]>(`${BASE_URL_API}/Wager/rules`)
      .then((response) => {
        setRules(response.data);
      })
      .catch((error) => {
        console.error("Error fetching rules:", error);
      });

    // Fetch gamertags from backend API
    axios
      .get<Gamertags>(`${BASE_URL_API}/User/gamertags`, {
        headers: {
          Authorization: `Bearer ${auth.user?.id_token}`,
        },
      })
      .then((response) => {
        setGamertags(response.data);
      })
      .catch((error) => {
        console.error("Error fetching gamertags:", error);
      });
  }, [auth.user]);

  useEffect(() => {
    if (size !== "1") {
      axios
        .get<Team[]>(`${BASE_URL_API}/User/admin/teams`, {
          headers: {
            Authorization: `Bearer ${auth.user?.id_token}`,
          },
        })
        .then((response) => {
          setTeams(response.data);
        })
        .catch((error) => {
          console.error("Error fetching teams:", error);
        });
    }
  }, [size, auth.user]);

  const handleListWager = () => {
    if (creditAmount === "") {
      showSnackbar("Credit amount must be provided", "error");
      return;
    } else if (!Number.isInteger(Number(creditAmount))) {
      showSnackbar("Credit amount must be an integer", "error");
      return;
    } else if (!selectedGamertag.trim()) {
      showSnackbar("Gamertag must be provided", "error");
      return;
    } else if (size !== "1" && !selectedTeam.trim()) {
      showSnackbar("Team must be selected for team wagers", "error");
      return;
    } else if (size !== "1" && selectedTeamMaxMembers !== parseInt(size)) {
      showSnackbar(
        `Selected team's members (${selectedTeamMaxMembers}) must match the wager size (${size})`,
        "error",
      );
      return;
    }

    // Prepare request body
    const requestBody = {
      wager: {
        gameName: game,
        type: `${size}v${size}`, // Assuming size format is used for 1v1, 2v2, etc.
        prizePool: parseInt(creditAmount),
        winlossdeclared: false,
        started: false,
        predefinedRules: [],
      },
      creatorPlayerTag: selectedGamertag,
      rulesId: selectedRules,
      ...(size !== "1" && { teamId: selectedTeam }),
    };
    const idToken = auth.user?.id_token;
    // API call to create wager
    axios
      .post(
        size === "1"
          ? `${BASE_URL_API}/Wager/single`
          : `${BASE_URL_API}/Wager/team`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        },
      )
      .then((response) => {
        // Handle success, e.g., show success message or redirect
        console.log("Wager created successfully:", response.data);
        showSnackbar("Wager listed successfully.", "success");
        navigate("/hub", {
          state: { wager: response.data.id, user: selectedGamertag },
        });
      })
      .catch((error) => {
        console.error("Error creating wager:", error);
        showSnackbar(error.response.data.message, "error");
      });
  };

  const handleTeamChange = (teamId: string) => {
    setSelectedTeam(teamId);
    const team = teams.find((t) => t.id === teamId);
    if (team) {
      setSelectedTeamMaxMembers(team.maxMembers);
    }
  };

  const userRed = useAppSelector((state) => state.user.value);

  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box sx={{ alignItems: "center", justifyContent: "center" }}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          mb: "2em",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Tooltip title="Back to Wagers" placement="bottom">
            <Button
              variant="contained"
              sx={{
                width: "5em",
                height: "3em",
                backgroundColor: isHovered
                  ? "rgba(0, 0, 0, 0.1)"
                  : "transparent",
                border: "none",
                boxShadow: "none",
                backgroundImage: `url(${Arrow})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              onClick={() => navigate(-1)}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginTop: "1em",
              fontWeight: "bold",
              textAlign: "center",
              width: "100%",
            }}
            color="white" // Set color to white
          >
            WAGER CREATION
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "2em",
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          width: isSmallScreen ? "20em" : isMediumScreen ? "30em" : "60em",
          margin: "0 auto",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            padding: "3em",
            flex: 1,
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            bgcolor: "rgba(128, 128, 128, 0.6)", // grey color with 0.6 opacity
            borderRadius: "8px",
            gap: "20px",
            border: "1px solid white",
          }}
        >
          {/* Left Section: Four Boxes and Select Rules */}
          <Box sx={{ marginRight: "2em", flex: 1 }}>
            <Typography
              variant="h6"
              sx={{ marginBottom: "20px" }}
              color="white"
            >
              WAGER DETAILS
            </Typography>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography color="white">Select game:</Typography>
              <Select
                value={game || ""}
                onChange={(e) => setGame(e.target.value as string)}
                sx={{
                  width: "100%",
                  color: "white", // Set text color to white
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white", // Set border color to white
                  },
                }}
              >
                <MenuItem disabled value="">
                  GAME
                </MenuItem>
                {games.map((gameInfo, index) => (
                  <MenuItem key={index} value={gameInfo.name}>
                    {gameInfo.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography color="white">Enter credits:</Typography>
              <TextField
                label="CREDIT AMOUNT"
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
                sx={{
                  width: "100%",
                  color: "white", // Set text color to white
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white", // Set border color to white
                  },
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography color="white">Team size:</Typography>
              <Select
                value={size || ""}
                onChange={(e) => setSize(e.target.value as string)}
                sx={{
                  width: "100%",
                  color: "white", // Set text color to white
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white", // Set border color to white
                  },
                }}
              >
                <MenuItem disabled value="">
                  SIZE
                </MenuItem>
                {game &&
                  games
                    .find((g) => g.name === game)
                    ?.sizes.map((size, index) => (
                      <MenuItem key={index} value={size.toString()}>
                        {size}
                      </MenuItem>
                    ))}
              </Select>
            </Box>
            <Box>
              <Typography color="white">Gamertag:</Typography>
              <Select
                value={selectedGamertag}
                onChange={(e) => {
                  console.log(e);
                  setSelectedGamertag(e.target.value as string);
                  console.log(e.target.value);
                }}
                sx={{
                  width: "100%",
                  color: "white", // Set text color to white
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white", // Set border color to white
                  },
                }}
              >
                {!!gamertags?.playstationGamertag && (
                  <MenuItem value={gamertags.playstationGamertag}>
                    {gamertags.playstationGamertag}
                  </MenuItem>
                )}
                {!!gamertags?.xboxGamertag && (
                  <MenuItem value={gamertags.xboxGamertag}>
                    {gamertags.xboxGamertag}
                  </MenuItem>
                )}
                {!!gamertags?.steamGamertag && (
                  <MenuItem value={gamertags.steamGamertag}>
                    {gamertags.steamGamertag}
                  </MenuItem>
                )}
                {!!gamertags?.riotGamesGamertag && (
                  <MenuItem value={gamertags.riotGamesGamertag}>
                    {gamertags.riotGamesGamertag}
                  </MenuItem>
                )}
                {!!gamertags?.activisionGamertag && (
                  <MenuItem value={gamertags?.activisionGamertag}>
                    {gamertags.activisionGamertag}
                  </MenuItem>
                )}
              </Select>
            </Box>
          </Box>

          <Box sx={{ flex: 1 }}>
            <Typography variant={"h6"} color={"aquamarine"}>
              Available balance: {userRed.balance} $
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: "10px" }}
              color="white"
            >
              Select rules to apply:
            </Typography>
            <Box sx={{ width: "100%" }}>
              {rules.map((rule) => (
                <FormControlLabel
                  key={rule.id}
                  control={
                    <Checkbox
                      checked={selectedRules.includes(rule.id)}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setSelectedRules((prevRules) => {
                          if (checked) {
                            return [...prevRules, rule.id];
                          } else {
                            return prevRules.filter((id) => id !== rule.id);
                          }
                        });
                      }}
                      sx={{
                        color: selectedRules.includes(rule.id)
                          ? "white"
                          : "white", // This will change the color of the checkbox when it's not checked
                      }}
                    />
                  }
                  label={rule.description}
                  sx={{ display: "block", marginBottom: "8px", color: "white" }}
                />
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            marginLeft: "2em",
            padding: "1.5em",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            bgcolor: "rgba(128, 128, 128, 0.6)", // grey color with 0.6 opacity
            borderRadius: "8px",
            height: "12em",
            border: "1px solid white",
          }}
        >
          <Typography color="white">
            Specific player in mind? Invite them through here
          </Typography>
          <TextField
            sx={{
              marginTop: "1em",
              width: { xs: "10em", sm: "10em", md: "15em" },
              color: "white", // Set text color to white
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white", // Set border color to white
              },
            }}
          />
          <Button variant="contained" sx={{ marginTop: "1em", width: "100px" }}>
            Invite
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
        <Button
          variant="contained"
          sx={{ marginTop: "4em", width: "150px" }}
          onClick={handleListWager}
        >
          Create Wager
        </Button>
      </Box>
    </Box>
  );
};

const WithAuthenticationRequiredProps = {
  onRedirecting: (): React.JSX.Element => (
    <div>Redirecting to the login page...</div>
  ),
};

export default withAuthenticationRequired(
  WagerCreation,
  //@ts-ignore
  WithAuthenticationRequiredProps,
);
