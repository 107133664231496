import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface messageType {
  userName: string;
  msg: string;
}

// Define a type for the slice state
export interface MessagesState {
  value: messageType[];
}

// Define the initial state using that type
const initialState: MessagesState = {
  value: [],
};

export const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<messageType>) => {
      state.value.push(action.payload);
    },
    clearMessage: (state) => {
      state.value = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMessage, clearMessage } = messageSlice.actions;

export default messageSlice.reducer;
