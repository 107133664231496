import React, { useState } from 'react';
import { TextField, Button, Grid, Typography } from '@mui/material';
import { TournamentDetail } from "../utils/types/TournamentDetail";

interface TournamentDetailFormProps {
  onSubmit: (tournamentDetail: TournamentDetail, id?: string) => void;
  isUpdate?: boolean;
}

const initialFormData: TournamentDetail = {
  CheckInPeriod: '',
  AvailableSlots: 0,
  ConfirmedTeamCount: 0,
  TournamentId: '',
  id: ''
};

const TournamentDetailForm: React.FC<TournamentDetailFormProps> = ({ onSubmit, isUpdate = false }) => {
  const [formData, setFormData] = useState<TournamentDetail>(initialFormData);
  const [tournamentDetailId, setTournamentDetailId] = useState<string | undefined>(undefined);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;
    setTournamentDetailId(id);
  };

  const handleSubmit = () => {
    onSubmit(formData, isUpdate ? tournamentDetailId : undefined);
    setFormData(initialFormData);
    setTournamentDetailId(undefined);
  };

  return (
    <form>
      <Grid container spacing={2}  sx={{ pl: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">{isUpdate ? 'Update Tournament Detail' : 'Add Tournament Detail'}</Typography>
        </Grid>
        {isUpdate && (
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Tournament Detail ID"
              name="tournamentDetailId"
              value={tournamentDetailId || ''}
              onChange={handleIdChange}
            />
          </Grid>
        )}
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Check In Period"
            name="CheckInPeriod"
            value={formData.CheckInPeriod}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Available Slots"
            name="AvailableSlots"
            type="number"
            value={formData.AvailableSlots}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Number of Confirmed Team"
            name="ConfirmedTeamCount"
            type="number"
            value={formData.ConfirmedTeamCount}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Tournament Id"
            name="TournamentId"
            value={formData.TournamentId}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={8}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {isUpdate ? 'Update' : 'Add'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default TournamentDetailForm;