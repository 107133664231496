import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Trophy2 from "../images/trophies-icon-2.png";
import Trophy3 from "../images/trophies-icon-3.png";
import Trophy4 from "../images/trophies-icon-4.png";

interface Props {
  gold: number;
  silver: number;
  bronze: number;
}

export default function BasicGrid({ gold, silver, bronze }: Props) {
  const images = [Trophy2, Trophy4, Trophy3];
  const bodyTexts = ["GOLD TROPHIES", "SILVER TROPHIES", "BRONZE TROPHIES"];
  const headers = [gold, silver, bronze];

  return (
    <Box
      bgcolor={"transparent"}
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems="center"
      width={{ xs: "100%", sm: "75%", md: "45%" }}
      height={{ xs: 330, sm: 150, md: 200 }}
      borderRadius="2em"
    >
      <Grid
        container
        spacing={{ xs: 0, md: 3 }}
        columns={{ xs: 3, sm: 12, md: 12 }}
      >
        {images.map((image, index) => (
          <Grid item xs={4} sm={4} md={4} key={index}>
            <Box
              sx={{
                borderRadius: "2em",
                height: "10em",
                width: "100%",
                bgcolor: "wheat",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  bgcolor: "rgba(128, 128, 128, 0.3)",
                  width: "5em",
                  height: "5em",
                  margin: "5%",
                  borderRadius: "50%",
                  backgroundImage: `url(${image})`,
                  backgroundPosition: "center",
                  backgroundSize: "50%",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <Box>
                <Typography variant="body1">{bodyTexts[index]}</Typography>
                <Typography variant="h5">{headers[index]}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
