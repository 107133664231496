import { BASE_URL_API } from "./fetchData";
import axios, { AxiosError } from "axios";

export const RequestPayment = async (
  amount: number,
  authToken?: string,
): Promise<any> => {
  const url = `${BASE_URL_API}/Payment/payout`;
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };

  // Add authorization header if authToken is provided
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  try {
    // Make the HTTP GET request using Axios
    const response = await axios.post(url, amount, { headers });

    // Check if the response is successful
    if (response.status !== 200) {
      throw new Error(
        `Failed to fetch data from ${url}: ${response.status} ${response.statusText}`,
      );
    }

    // Return the data from the response
    return response.data;
  } catch (error) {
    // Handle Axios errors
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(
          `Request failed with status ${axiosError.response.status}:`,
          axiosError.response.data,
        );
      } else if (axiosError.request) {
        // The request was made but no response was received
        console.error("No response received:", axiosError.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Request setup error:", axiosError.message);
      }
    } else {
      // Handle other types of errors
      console.error("Error fetching data:", error);
    }

    // Re-throw the error to be handled by the caller
    throw error;
  }
};
