import React from 'react';
import { Box, Card, CardContent, CardMedia, Grid, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import TournamentRightSide from './TournamentInfoRightSide';
import axios from 'axios';

import image1 from '../images/format-icon-1.png';
import image2 from '../images/format-icon-2.png';
import image3 from '../images/format-icon-3.png';
import image4 from '../images/format-icon-4.png';
import image5 from '../images/format-icon-5.png';
import image6 from '../images/format-icon-6.png';

interface BoxContent {
  image: string;
  header: string;
  body: string;
  bgcolor: string;
}

interface TournamentInfoProps {
  tournamentData: any;
}



const Tournament: React.FC<TournamentInfoProps> = ({tournamentData}) => {
  const boxData: BoxContent[] = [
    { image: image1, header: 'GAME', body: tournamentData.gameName, bgcolor: 'linear-gradient(to bottom, rgba(193, 175, 247, 0.3), rgba(91, 73, 145, 0.3))' },
    { image: image2, header: 'TEAM SIZE', body: tournamentData.teamSize, bgcolor: 'linear-gradient(to bottom, rgba(193, 175, 247, 0.3), rgba(91, 73, 145, 0.3))' },
    { image: image3, header: 'PRIZE POOL', body: `$${tournamentData.prizePool}`, bgcolor: 'linear-gradient(to bottom, rgba(193, 175, 247, 0.3), rgba(91, 73, 145, 0.3))' },
    { image: image4, header: 'CHECK-IN PERIOD', body: tournamentData.detail.checkInPeriod, bgcolor: 'linear-gradient(to bottom, rgba(193, 175, 247, 0.3), rgba(91, 73, 145, 0.3))' },
    { image: image5, header: 'ENTRY FEE', body: `$${tournamentData.entryFee}`, bgcolor: 'linear-gradient(to bottom, rgba(193, 175, 247, 0.3), rgba(91, 73, 145, 0.3))' },
    { image: image6, header: 'SKILL LEVEL', body: tournamentData.skillLevel, bgcolor: 'linear-gradient(to bottom, rgba(193, 175, 247, 0.3), rgba(91, 73, 145, 0.3))' },
  ];
  const bulletPoint = (
    <Box
      component="span"
      sx={{
        width: '6px',
        height: '6px',
        backgroundColor: 'black',
        borderRadius: '50%',
        display: 'inline-block',
        mr: 2,
        alignSelf: 'center'
      }}
    />
  );

  return (
    <Box sx={{display: 'flex', justifyContent: 'center' }}>
      <Grid container style={{  width: '70em',maxWidth: '100%', overflow: 'auto' }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={12} sm={7} md={7}>
          <div>
          <Typography sx={{marginBottom:'-0.1em', marginLeft: '0.5em', marginTop:'0.5em'}} variant="h4" component="h1" gutterBottom>
            Format
          </Typography>
            <Grid container columns={{ xs: 1, sm: 8, md: 8 }}>
              
              {boxData.map((box, index) => (
                <Grid item xs={2} sm={4} md={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Card
                    sx={{
                      m: '1em',
                      width: "20rem",
                      height: "8rem",
                      borderRadius: '0.4em',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: box.bgcolor,
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt={box.header}
                      sx={{
                        objectFit: 'contain', 
                        width: '2em', 
                        height: '2em', 
                        marginBottom:'-0.7em',
                        marginTop:'1em'
                      }}
                      image={box.image}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        component="div"
                        align="center"
                        sx={{
                          marginBottom: '0.4em',
                          fontSize: '1em',
                          color: 'black', 
                        }}
                      >
                        {box.header}
                      </Typography>
                      <Typography
                        variant="body2"
                        align="center"
                        sx={{
                          marginBottom: '0.1em',
                          fontSize: '0.9em',
                          color: 'black', 
                        }}
                      >
                        {box.body}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ p: 3 }}>
              <Typography variant="h4" gutterBottom>
                Tournament Information
              </Typography>
              <Box sx={{ border: 0, background: 'transparent' }}>
                <Typography variant="h6" gutterBottom>
                  FORMAT
                </Typography>
                <List sx={{ padding: 0 }}>
                  {['Map pool: Selected Aim Maps', 'All kind of cheating will result in a permanent suspension from Begam.', 'Playing with a cheater will result in a 3 month suspension from Begam.', 'Toxic behaviour will cause warnings and in severe cases both disqualifications and suspensions.'].map((text, index) => (
                    <ListItem key={index} sx={{ py: 0.1 }}>
                      <ListItemIcon>{bulletPoint}</ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="h6" gutterBottom>
                  PRIZE CLAIM
                </Typography>
                <List sx={{ padding: 0 }}>
                  <ListItem sx={{ py: 0.1 }}>
                    <ListItemIcon>{bulletPoint}</ListItemIcon>
                    <ListItemText primary="Prize claims must be completed within 24 hours of the end of the tournament otherwise risk penalty of the prize. Claims can take up to 72 hours to be processed." />
                  </ListItem>
                </List>
                <Typography variant="h6" gutterBottom>
                  RULES
                </Typography>
                <List sx={{ padding: 0 }}>
                  {['Please be respectful to your host and other participants. If any malicious behavior is reported, you will be removed from the tournament.', 'Please be on time for your registration and for the actual tournament. You (and your team) will be disqualified on no-show.', 'You and all of your teammates must be registered to qualify for the event.', 'You can play in this tournament only if your registered and in-game names match, otherwise you will be disqualified.'].map((text, index) => (
                    <ListItem key={index} sx={{ py: 0.1 }}>
                      <ListItemIcon>{bulletPoint}</ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <TournamentRightSide tournamentData = {tournamentData}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Tournament;
