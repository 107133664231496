import React from "react";
import Typography from "@mui/material/Typography";

const ShopDetails = () => {
  return (
    <div className="flex flex-col px-10 py-5">
      <Typography variant="h4">Shop Details</Typography>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget ipsum
        velit. Nullam id urna id purus scelerisque sollicitudin. Integer id nisi
        sed metus placerat dapibus ac eget eros. Donec viverra velit nec enim
        convallis, sit amet hendrerit justo lobortis. Integer et diam sapien.
        Sed varius tristique quam ac posuere. Nam quis nisi condimentum, aliquam
        libero id, consectetur magna. Integer interdum consequat urna at
        lacinia.
      </p>
    </div>
  );
};

export default ShopDetails;
