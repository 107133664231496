import React, { useEffect, useState } from "react";
import {
  Stack,
  FormControl,
  Box,
  Select,
  MenuItem,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
  SelectChangeEvent,
} from "@mui/material";
import timer from "../images/waitng-icon.png";
import coin from "../images/price-coin.png";
import Highlight from "../components/HighlightTournament";
import { Tournament as TournamentData } from "../utils/types/Tournament";
import { fetchTournamentData } from "../services/tournamentService";
import Countdown from "../components/Countdown";
import FormatDate from "../components/FormatDate";
import { useNavigate } from "react-router-dom";
import { fetchGameData } from "../services/gameService";
import { Game } from "../utils/types/Game";
import { useTheme } from "@mui/material";
import { theme } from "../utils/theme";


const styles = {
  InfoBox: {
    textAlign: "center",
    p: { xl: "10px 10px", xs: "8px 8px" },
    border: "1px solid #76ABAE",
    fontSize: "11px",
    mx: { xl: "8px", xs: "2px" },
    textTransform: "uppercase",
    lineHeight: "20px",
    width: { xs: "100%", sm: "auto" },
    my: { xs: "5px", sm: "0px" },
    letterSpacing: 0.6
  },
};

const TournamentCard: React.FC<{ tournament: TournamentData }> = ({
  tournament,
}) => {
  const navigate = useNavigate();
  const handleViewTournament = () => {
    navigate(`/tournamentsingle/${tournament.id}`);
  };
  const theme = useTheme()


  return (
  <Grid
    container
    p="20px"
    mb="20px"
    alignItems="center"
    justifyContent="center"
    sx={{backgroundColor: theme.palette.primary.main, border: `1px solid ${theme.palette.primary.dark}`, borderRadius: 2 }}
  >
    <Grid
      container
      item
      xs={12}
      md={3}
      lg={3}
      justifyContent="center"
      alignItems="center"
      pb={{ md: "0", xs: "20px" }}
    >
      <Box
        component="img"
        src={tournament.imageUrl}
        alt={tournament.name}
        sx={{
          width: "100%", 
          height: "auto" ,
          borderRadius: 2
        }}
      />
    </Grid>
    <Grid
      container
      item
      xs={12}
      md={9}
      lg={6}
      justifyContent="center"
      alignItems="center"
      px="10px"
      pb={{ lg: "0", xs: "20px" }}
    >
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        borderBottom="1px solid #76ABAE"
      >
        <Typography
          variant="h4"
          fontSize={{ xs: "18px", sm: "24px", lg: "32px" }}
          fontWeight="bold"
          mb="10px"
          sx={{fontFamily: "Orbitron, Arial, sans-serif", color: theme.palette.primary.dark}}
        >
          {tournament.name}
        </Typography>
        <Grid
          container
          item
          pb="10px"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="10px"
            mr={{ xs: "0px", sm: "5px" }}
            my="10px"
            borderRadius="5px"
            sx={{ background: theme.palette.primary.light }}
            width={{ xs: "100%", sm: "auto" }}
          >
            <img src={timer} alt="timer" style={{ marginRight: "5px" }} />
            <Typography
              fontSize={{ xs: "14px", sm: "16px", md: "18px" }}
              display="flex"
              color={theme.palette.text.secondary}
            >
              Starts in&nbsp; <Countdown startDate={tournament.startDate} />
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="10px"
            ml={{ xs: "0px", sm: "5px" }}
            my="10px"
            borderRadius="5px"
            sx={{ background: theme.palette.primary.light }}
            width={{ xs: "100%", sm: "auto" }}
          >
            <Typography
              fontSize={{ xs: "14px", sm: "16px", md: "18px" }}
              color={theme.palette.text.secondary}
            >
              <FormatDate startDate={tournament.startDate} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        mt="20px"
      >
        <Box display="flex" width={{ xs: "100%", sm: "auto" }}>
        <Typography sx={styles.InfoBox}>
          Credits / Player <br />
          <Box fontSize="13px" fontWeight="bold" color={theme.palette.primary.dark} fontFamily={"Orbitron, Arial, sans-serif"}>
            {tournament.entryFee}
          </Box>
        </Typography>

        <Typography sx={styles.InfoBox}>
          Team Size <br />
          <Box fontSize="13px" fontWeight="bold" color={theme.palette.primary.dark} fontFamily={"Orbitron, Arial, sans-serif"}>
            {tournament.teamSize}
          </Box>
        </Typography>
      </Box>
      <Box display="flex" width={{ xs: "100%", sm: "auto" }}>
        <Typography sx={styles.InfoBox}>
          Max Teams <br />
          <Box fontSize="13px" fontWeight="bold" color={theme.palette.primary.dark} fontFamily={"Orbitron, Arial, sans-serif"}>
            {tournament.maxTeamCount}
          </Box>
        </Typography>

        <Typography sx={styles.InfoBox}>
          Team Registered <br />
          <Box fontSize="13px" fontWeight="bold" color={theme.palette.primary.dark} fontFamily={"Orbitron, Arial, sans-serif"}>
            {tournament.registeredTeamCount}
          </Box>
        </Typography>
      </Box>
      </Grid>
    </Grid>
    <Grid
      container
      item
      xs={12}
      lg={3}
      justifyContent="center"
      alignItems="center"
      pl={{ xs: "0px", lg: "15px" }}
    >
      <Card sx={{ width: "100%", background: theme.palette.primary.light, color: theme.palette.text.secondary, border: `1px solid ${theme.palette.primary.dark}`,  }}>
        <CardContent sx={{ p: "0px !important", my: {xs: 2, lg: '75px'}  }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb="6px"
          >
            <img src={coin} alt="coin" style={{ marginRight: "5px" }} />
            <Typography fontSize="14px" fontFamily={"Orbitron, Arial, sans-serif"}>PRIZE POOL</Typography>
          </Box>
          <Typography
            variant="h4"
            textAlign="center"
            fontSize={{ xs: "18px", sm: "24px", lg: "32px" }}
            fontFamily={"Orbitron, Arial, sans-serif"}
          >
            ${tournament.prizePool}
          </Typography>
          <CardActions
            sx={{
              justifyContent: "center",
              alignItems: "center",
              px: "0",
              py: "0",
              my: "10px",
            }}
          >
            <Button
              sx={{
                display: "inline-block",
                px: 4,
                py: 1,
                fontSize: "14px",
                background: theme.palette.primary.dark,
                color: theme.palette.text.secondary,
                fontFamily: "Orbitron, Arial, sans-serif",
                '&:hover': {
                  background: theme.palette.primary.main,
                }
              }}
              onClick={handleViewTournament}
            >
              View Tournament
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
)};

const Tournament: React.FC = () => {
  const [tournaments, setTournaments] = useState<TournamentData[]>([]);
  const [games, setGames] = useState<Game[]>([]);
  const [filters, setFilters] = useState({
    game: "",
    teamSize: "",
    entryFee: 0,
  });

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const [tournamentResult, gameResult] = await Promise.all([
          fetchTournamentData(),
          fetchGameData(),
        ]);
        setTournaments(tournamentResult);
        setGames(gameResult);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataAsync();
  }, []);

  const handleSelectChange = (
    event: SelectChangeEvent<number | string>,
    filterKey: string
  ) => {
    setFilters((prevState) => ({
      ...prevState,
      [filterKey]: event.target.value,
    }));
  };

  const filteredTournaments = tournaments.filter((tournament) => {
    const teamSizeNumber = tournament.teamSize.match(/\d+/)?.[0] || "";

    return (
      (filters.game === "" || tournament.gameName === filters.game) &&
      (filters.teamSize === "" || teamSizeNumber === filters.teamSize) &&
      (filters.entryFee === 0 || tournament.entryFee === filters.entryFee)
    );
  });

  return (
    <div>
      <Highlight />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack
          maxWidth={{ xl: "1200px", lg: "1100px", md: "720px", sm: "540px" }}
          py={{ xs: "50px", sm: "60px", md: "120px" }}
          px="15px"
        >
          <Grid
            xs={8}
            justifyContent="center"
            alignItems="center"
            display="flex"
            px="15px"
          >
            <Box>
              <Typography
                variant="h2"
                textAlign="center"
                fontSize={{ xs: "34px", sm: "40px", md: "62px" }}
                fontFamily={"Orbitron, Arial, sans-serif"}
                marginBottom={2}
                color={theme.palette.primary.dark}
              >
                Browse Tournaments
              </Typography>
              <Typography
                textAlign="center"
                mx="15%"
                fontSize={{ xs: "18px", sm: "16px", md: "20px" }}
                fontFamily={"Orbitron, Arial, sans-serif"}
                marginBottom={{xs: 1, md: 8 }}
              >
                Find the perfect tournament for you. Head to head matches where
                you pick the game, rules and prize.
              </Typography>
            </Box>
          </Grid>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            my={5}
          >
            <Box pr={{ xs: "0px", md: "15px" }} width="100%">
              <Typography fontFamily={"Orbitron, Arial, sans-serif"} variant="subtitle1" marginLeft={1}>Game</Typography>
              <FormControl margin="dense" sx={{ width: "100%" }}>
              <Select
          value={filters.game}
          onChange={(event) => handleSelectChange(event, "game")}
          displayEmpty
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.dark,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '& .MuiSvgIcon-root': {
              color: 'white',
            },
            color: 'white',
            backgroundColor: theme.palette.primary.main
          }}
        >
                  <MenuItem value="">Select Game</MenuItem>
                  {games.map((game) => (
                    <MenuItem value={game.name}>{game.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              px={{ xs: "0px", md: "15px" }}
              mt={{ xs: 2, sm: 0 }}
              width="100%"
            >
              <Typography fontFamily={"Orbitron, Arial, sans-serif"} variant="subtitle1" marginLeft={1}>Team Size</Typography>
              <FormControl margin="dense" sx={{ width: "100%" }}>
                <Select
                  value={filters.teamSize}
                  onChange={(event) => handleSelectChange(event, "teamSize")}
                  displayEmpty
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.primary.dark,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                    color: 'white',
                    backgroundColor: theme.palette.primary.main
                  }}
                >
                  <MenuItem value="">Select Team Size</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              pl={{ xs: "0px", md: "15px" }}
              mt={{ xs: 2, sm: 0 }}
              width="100%"
            >
              <Typography fontFamily={"Orbitron, Arial, sans-serif"} variant="subtitle1" marginLeft={1}>Entry Fee</Typography> 
              <FormControl margin="dense" sx={{ width: "100%" }}>
                <Select
                  value={filters.entryFee}
                  onChange={(event) => handleSelectChange(event, "entryFee")}
                  displayEmpty
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.primary.dark,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                    color: 'white',
                    backgroundColor: theme.palette.primary.main
                  }}
                >
                  <MenuItem value={0}>Select Entry Fee</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={150}>150</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            {filteredTournaments.map((tournament, index) => (
              <TournamentCard key={index} tournament={tournament} />
            ))}
          </Box>
        </Stack>
      </Box>
    </div>
  );
};

export default Tournament;
