import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HubConnection } from "@microsoft/signalr";

// Define a type for the slice state
export interface CounterState {
  value: HubConnection | undefined;
}

// Define the initial state using that type
const initialState: CounterState = {
  value: undefined,
};

export const hubConnection = createSlice({
  name: "hubConnection",
  initialState,
  reducers: {
    setConnection: (state, action: PayloadAction<HubConnection>) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConnection } = hubConnection.actions;

export default hubConnection.reducer;
