import { postData } from "./fetchData";

export const emailPost = async (bodyData: emailDto, authToken?: string) => {
  return await postData("email", bodyData, authToken);
};

export type emailDto = {
  subject: string;
  body: string;
};
