// ExampleComponent.jsx

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box, Card, CardContent, CardMedia, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, Margin } from "@mui/icons-material";
import { fetchTournamentData } from "../services/tournamentService";
import { Tournament } from "../utils/types/Tournament";
import { useTheme } from "@mui/material";
import { theme } from "../utils/theme";

const styles = {
  sliderContainer: {
    width: "100%",
    maxWidth: "1024px",
    mx: "auto",
  },
  slider: {
    display: "flex",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    "&::-webkit-scrollbar": {
      height: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.primary.dark,
      cursor: 'pointer'
    },
  },
  cardContainer: {
    scrollSnapAlign: "start",
    flex: "0 0 auto",
    width: {
      xs: '100%',
      sm: "50%",
      md: "32.1%",
    },
    m: 1,
    boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
    borderRadius: "8px",
    overflow: "hidden",
  },
};

function TournamentCard({ tournament }: { tournament: Tournament }) {
  
  return (
    <Card
      sx={{
        maxWidth: 380,
        minWidth: 300,
        bgcolor: "transparent",
        m: 1,
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        "&:hover": {
          boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
        },
        borderRadius: 1,
        overflow: "hidden",
        
      }}
    >
      <CardMedia
        sx={{ height: 220}}
        image={tournament.imageUrl}
        title={tournament.name}
      />
      <CardContent sx={{ color: theme.palette.text.secondary}}>
        <Typography gutterBottom variant="h5" component="div">
          {tournament.name}
        </Typography>
        <Typography variant="body2">
          Date: {new Date(tournament.startDate).toLocaleDateString()}
        </Typography>
        <Typography variant="body2">
          Prize Pool: ${tournament.prizePool.toLocaleString()}
        </Typography>
        <Typography variant="body2">
          Entry Fee: ${tournament.entryFee.toLocaleString()}
        </Typography>
      </CardContent>
    </Card>
  );
}

const AvailableTournamentComponent = () => {
  const theme = useTheme()
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const result = await fetchTournamentData();
        setTournaments(result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataAsync();
  }, []);

  const scroll = (direction: number) => {
    const container = scrollRef.current;
    if (container) {
      const cardWidth = (container.firstChild as HTMLElement)?.clientWidth ?? 0; // Proper casting
      container.scrollLeft += cardWidth * direction;
    }
  };

  return (
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{my: 10, textAlign: 'center'}}>
        <Typography variant="h1" sx={{ marginBottom: 2, color: theme.palette.primary.dark, fontFamily: 'Orbitron, Arial, sans-serif', }}>Featured Tournaments</Typography>
        <Typography variant="h2" sx={{fontFamily: 'Orbitron, Arial, sans-serif'}}>

          We are constantly adding new tournaments
        </Typography>
        <Box sx={{p:10}}>
          <IconButton
            onClick={() => scroll(-3)}
            sx={{
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
              color: theme.palette.primary.dark
            }}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            onClick={() => scroll(3)}
            sx={{
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
              color: theme.palette.primary.dark
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </Box>
        <Box style={styles.sliderContainer}>
          <Box sx={styles.slider} ref={scrollRef}>
            {tournaments.map((tournament, key) => (
              <Box sx={styles.cardContainer} key={key}>
                <TournamentCard tournament={tournament} />
              </Box>
            ))}
          </Box>
        </Box>
        <Box display='flex' justifyContent='center' sx={{mt: 5}}>
          <Button variant="contained" onClick={() => navigate("/tournament")} sx={{backgroundColor: theme.palette.primary.dark, width: 150, borderRadius: 1, p: 1, fontFamily: "Orbitron, Arial, sans-serif", }}>
            VIEW ALL
          </Button>
        </Box>
      </Box>
  );
};

export default AvailableTournamentComponent;
