import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import emailIcon from "../images/email-icon.png";
import phoneIcon from "../images/phone-icon.png";
import { useAuth } from "react-oidc-context";
import { emailDto, emailPost } from "../services/emailService";

export default function ContactUs() {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [bodyError, setBodyError] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = useAuth();

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const handleBodyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBody(event.target.value);
  };

  const validateForm = () => {
    let valid = true;

    if (subject.length > 50) {
      setSubjectError("Subject cannot not go over 50 characters");
      valid = false;
    } else {
      setSubjectError("");
    }

    if (body.length > 500) {
      setBodyError("Body cannot not go over 500 characters");
      valid = false;
    } else {
      setBodyError("");
    }

    return valid;
  };

  const handleSubmit = () => {
    setSubmitClicked(true);
    if (validateForm()) {
      setIsSubmitting(true);
      const idToken = auth.user?.id_token;
      console.log(idToken);
      let emailContent: emailDto = {
        subject: subject,
        body: body,
      };
      emailPost(emailContent, idToken)
        .then(() => {
          setIsSubmitting(false);
        })
        .catch((e) => {
          console.log(e);
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (!isSubmitting) {
      setSubject("");
      setBody("");
    }
  }, [isSubmitting]);
  

  // Custom round box component
  const RoundBox = ({ src, alt }: { src: string; alt: string }) => (
    <Box
      sx={{
        width: "3.8em",
        height: "3.8em",
        borderRadius: "50%",
        backgroundColor: "#543D90",
        backgroundImage: `url(${src})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "60%",
      }}
      role="img"
      aria-label={alt}
    />
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "80vh",
        textAlign: "center",
        gap: "1rem",
        pt: "2rem",
        marginBottom: "33em",
      }}
    >
      <Typography
        variant="subtitle1"
        component="div"
        sx={{ fontWeight: "bold", fontSize: { xs: '1rem', md: '1.5rem', lg: '2rem' } }}
      >
        CONTACT US
      </Typography>
      <Typography variant="h1" component="div" sx={{ fontWeight: "bold", fontSize: { xs: '1.5rem', md: '2.5rem', lg: '3rem' } }}>
        GET IN TOUCH TODAY!
      </Typography>
      <Typography variant="body1" component="div" sx={{ fontSize: { xs: '0.8rem', md: '1rem', lg: '1.2rem' } }}>
        We're here to support your dreams. We're here to help
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "stretch",
          justifyContent: "space-between",
          width: { xs: "23em", md: "55em", lg: "70em" },
          height: { xs: "44em", md: "44em", lg: "44em" },
          mt: "2rem",
        }}
      >
        <Box
          sx={{
            borderRadius: "2em",
            flex: "0 0 67%",
            height: "94%",
            backgroundColor: "white",
            paddingY: "2em", // Add some padding
            paddingX: "3.5em",
            textAlign: "left", // Align text to the left
          }}
        >
          <Typography
            variant="h3"
            component="div"
            sx={{ fontWeight: "bold", mb: "2rem" , fontSize: { xs: '1rem', md: '1rem', lg: '1.5rem' } }}
          >
            LEAVE YOUR MESSAGE
          </Typography>
          <Typography variant="body1" component="div" sx={{ mb: "1rem" , fontSize: { xs: '0.9rem', md: '1rem', lg: '1.2rem' }}}>
            Subject
          </Typography>
          <TextField
            error={submitClicked && !!subjectError}
            helperText={submitClicked && subjectError}
            value={subject}
            onChange={handleSubjectChange}
            placeholder="Enter the Subject"
            fullWidth
            sx={{ mb: "2rem" }}
          />
          <Typography variant="body1" component="div" sx={{ mb: "1rem" , fontSize: { xs: '0.9rem', md: '1rem', lg: '1.2rem' }}}>
            Body
          </Typography>
          <TextField
            error={submitClicked && !!bodyError}
            helperText={submitClicked && bodyError}
            value={body}
            onChange={handleBodyChange}
            placeholder="Enter the Body"
            fullWidth
            multiline
            rows={5} // Adjust as needed
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "2rem" }}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </Box>
        <Box
          sx={{
            flex: "0 0 30%",
            height: "100%",
            backgroundColor: "transparent",
            textAlign: "left", // Align text to the left
            p: 2, // Add some padding
            mt:'3em'

          }}
        >
          {/* Right box content goes here */}
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold", mb: "2rem" }}
          >
            MORE INFORMATION
          </Typography>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <RoundBox src={emailIcon} alt="Email icon" />
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "bold", fontSize: { xs: '1rem', md: '1rem', lg: '1.3rem' } }}
              >
                EMAIL
              </Typography>
              <Typography variant="body2" component="div">
                esportium@gmail.com
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ mt: "2rem" }}>
            <Grid item>
              <RoundBox src={phoneIcon} alt="Phone icon" />
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "bold", fontSize: { xs: '1rem', md: '1rem', lg: '1.3rem' } }}
              >
                PHONE
              </Typography>
              <Typography variant="body2" component="div">
                (123)456-7890
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
