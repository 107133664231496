import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import Icon1 from "../images/how-icon-1.png";
import Icon2 from "../images/how-icon-2.png";
import Icon3 from "../images/how-icon-3.png";
import Icon4 from "../images/how-icon-4.png";
import { useTheme } from "@mui/material/styles";
import { theme } from "../utils/theme";

function IconCards() {
  const theme = useTheme();
  const cards = [
    { icon: Icon1, text: "SIGNUP" },
    { icon: Icon2, text: "DEPOSIT" },
    { icon: Icon3, text: "COMPETE" },
    { icon: Icon4, text: "GET PAID" },
  ];

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{ marginTop: 5, width: '88%' }}
    >
      {cards.map((card, index) => (
        <Grid
          item
          key={index}
          xs={12}
          sm={6}
          md={3}
          lg={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              width: 125,
              height: 125,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: theme.palette.primary.light,
              border: "1px solid",
              borderColor: theme.palette.text.secondary,
              position: "relative",
              overflow: "visible", 
            }}
          >
              <Box
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                width: 24,
                height: 24,
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: 14,
                fontWeight: "bold",
                overflow: "visible"
              }}
            >
              {index + 1}
            </Box>
            <CardContent
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={card.icon}
                alt={card.text}
                style={{ width: 50, height: 50 }}
              />
            </CardContent>
          </Card>
          <Typography sx={{ textAlign: "center", mt: 1, fontFamily: "Orbitron, Arial, sans-serif"}} variant="subtitle1">
            {card.text}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

const HowItWorksComponent = () => {
  return (

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign='center'
        sx={{ my: 10 }}
      >
        <Typography
          variant="h1"
          sx={{
            marginBottom: 2,
            color: theme.palette.primary.dark,
            fontFamily: "Orbitron, Arial, sans-serif",
          }}
        >
          HOW ESPORTIUM WORKS
        </Typography>
        <Typography
          variant="h3"
          sx={{ fontFamily: "Orbitron, Arial, sans-serif" }}
        >
          It's easier than you think. Follow 4 simple easy steps
        </Typography>
        <IconCards />
        <Button
          sx={{
            mt: 2,
            backgroundColor: theme.palette.primary.dark,
            width: 150,
            borderRadius: 1,
            p: 1,
            fontFamily: "Orbitron, Arial, sans-serif",
          }}
          variant="contained"
        >
          JOIN NOW
        </Button>
      </Box>
  );
};

export default HowItWorksComponent;
