import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface TournamentInfoProps {
  tournamentData:  {
    prizePool: number;
  };
}

const getPlacementStyle = (placement: string) => {
  switch (placement) {
    case '1st':
      return { fontWeight: 'bold', color: 'gold' };
    case '2nd':
      return { fontWeight: 'bold', color: 'silver' };
    case '3rd':
      return { fontWeight: 'bold', color: '#cd7f32' }; 
    default:
      return { fontWeight: 'bold' };
  }
};

const calculatePrizes = (prizePool: number) => {
  const breakdown = [
    { placement: '1st', percentage: 0.65 },
    { placement: '2nd', percentage: 0.15 },
    { placement: '3rd', percentage: 0.10 },
    { placement: '4th', percentage: 0.06 },
    { placement: '5th', percentage: 0.04 },
  ];

  return breakdown.map((item) => ({
    placement: item.placement,
    currentPrize: `$${(prizePool * item.percentage).toFixed(2)}`,
    potentialPrize: `$${(prizePool * item.percentage).toFixed(2)}`,
    team: 'TBD', 
    teamImage: '', 
  }));
};

const PrizesTable: React.FC<TournamentInfoProps> = ({tournamentData}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const prizeData = calculatePrizes(tournamentData.prizePool);
  return (
    <TableContainer component={Paper} sx={{ width: isSmallScreen ? '90%' : '60%', margin: 'auto', bgcolor: 'transparent' }}>
      <Table sx={{ minWidth: 650, width: '100%' }} aria-label="prizes table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '25%', textAlign: 'left', fontWeight: 'bold' }}>Placement</TableCell>
            <TableCell sx={{ width: '25%', textAlign: 'left' }}>Current Prize</TableCell>
            <TableCell sx={{ width: '25%', textAlign: 'left' }}>Potential Prize</TableCell>
            <TableCell sx={{ width: '25%', textAlign: 'left' }}>Team</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prizeData.map((row) => (
            <TableRow key={row.placement}>
              <TableCell component="th" scope="row" sx={{ ...getPlacementStyle(row.placement), textAlign: 'left' }}>
                {row.placement}
              </TableCell>
              <TableCell sx={{ textAlign: 'left' }}>{row.currentPrize}</TableCell>
              <TableCell sx={{ textAlign: 'left' }}>{row.potentialPrize}</TableCell>
              <TableCell sx={{ textAlign: 'left', verticalAlign: 'middle' }}>
                <Avatar alt="Team Image" src={row.teamImage} sx={{ display: 'inline-block', marginRight: '8px', verticalAlign: 'middle' }} />
                {row.team}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PrizesTable;
