import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Team } from "../utils/types/Team";
import { uploadImage } from "../utils/AwsUtils";
import { useAuth } from "react-oidc-context";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "./SnackbarProvider";
import { BASE_URL_API } from "../services/fetchData";

const initialFormData: Team = {
  id: "",
  name: "",
  description: "",
  imageUrl: "",
  maxMembers: 2,
  entryFee: 10,
  Admin: {},
};

const Users1 = ["user1", "user2", "user3", "user4", "user5", "user6", "user7"];

interface TeamCreationProps {
  isOpen: boolean;
  onClose: () => void;
}

const TeamCreation: React.FC<TeamCreationProps> = ({ isOpen, onClose }) => {
  const [users, setUsers] = useState<[]>();
  const [formData, setFormData] = useState<Team>(initialFormData);
  const [logo, setLogo] = useState<File | null>(null);
  const [logoFileName, setLogoFileName] = useState<string | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const [errors, setErrors] = useState<{
    name?: string;
    description?: string;
    logo?: string;
  }>({});

  const auth = useAuth();

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const idToken = auth.user?.id_token;
        const response = await fetch(`${BASE_URL_API}/User`, {
          method: "GET",
          headers: { Authorization: `Bearer ${idToken}` },
        });

        const users = await response.json();
        setUsers(users);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
  }, []);

  const handleSendInvite = () => {
    console.log("Send invite to:");
    console.log("users ", users);
    // Handle sending invite logic
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setLogo(file);
      setLogoFileName(file.name);
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setLogo(null);
    setLogoFileName(null);
    setOpen(false);
    onClose();
    setErrors({});
  };

  const validateForm = () => {
    const newErrors: { name?: string; description?: string; logo?: string } =
      {};
    if (!formData.name) newErrors.name = "Team Name is required";
    if (!formData.description)
      newErrors.description = "Team Description is required";
    if (!logo) newErrors.logo = "Team Logo is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateTeam = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const idToken = auth.user?.id_token;
      if (idToken && logo) {
        const uploadResponse = await uploadImage(logo, idToken, "Teams");
        if (uploadResponse) {
          formData.imageUrl = uploadResponse.path;
        } else {
          throw new Error("Image upload failed.");
        }

        const { id, ...formDataWithoutId } = formData;

        const response = await fetch(`${BASE_URL_API}/Teams`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(formDataWithoutId),
        });

        if (response.ok) {
          showSnackbar("Team created successfully.", "success");

          handleCancel();
        } else {
          const errorData = await response.json();
          showSnackbar(errorData.detail, "error");
        }
      } else {
        throw new Error("ID token is missing.");
      }
    } catch (error) {
      showSnackbar((error as Error).message || "An error occurred.", "error");

      console.log(error);
    }
  };

  const handleOpen = () => {
    if (auth.isAuthenticated) {
      setOpen(true);
    } else {
      showSnackbar("You must be logged in to create a team.", "error");
    }
  };

  return (
    <Box display="flex">
      <Button variant="contained" onClick={handleOpen} sx={{ pl: "10px" }}>
        <AddIcon sx={{ mr: "5px" }} /> Create Team
      </Button>
      <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
        <DialogTitle>Team Creation</DialogTitle>
        <DialogContent dividers>
          <FormControl
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleCreateTeam();
            }}
            fullWidth
          >
            <FormGroup>
              <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                  label="Team Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  fullWidth
                />
                <TextField
                  label="Team Description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  error={Boolean(errors.description)}
                  helperText={errors.description}
                  fullWidth
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                >
                  <Autocomplete
                    multiple
                    id="username"
                    options={Users1}
                    renderInput={(params) => (
                      <TextField {...params} label="Search username" />
                    )}
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    sx={{ width: "25%" }}
                    onClick={handleSendInvite}
                  >
                    Send Invite
                  </Button>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="200px"
                  border="1px solid #ccc"
                  margin="normal"
                  flexDirection="column"
                >
                  {logoFileName && (
                    <Typography variant="body1">{logoFileName}</Typography>
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    sx={{ marginTop: "10px" }}
                  >
                    Upload Logo
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleLogoChange}
                    />
                  </Button>
                  {errors.logo && (
                    <Typography color="error">{errors.logo}</Typography>
                  )}
                </Box>
              </Box>
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ px: "24px" }}>
          <Typography variant="body1" sx={{ marginRight: "auto" }}>
            Entry Fee: 10 credits
          </Typography>
          <Button onClick={handleCancel} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleCreateTeam}
            variant="contained"
            color="primary"
          >
            Create Team
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TeamCreation;
