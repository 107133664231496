import * as React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import Logo from "../images/eSportiumLogo.png";
import { BASE_URL } from "../services/fetchData";
import { useTheme } from "@mui/material";
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { fetchUserId } from "../services/userService";
import { useAppSelector } from "../hooks";
import { useDispatch } from "react-redux";
import { setConnection } from "../reducers/hubConnection";
import { updateId } from "../reducers/userReducer";

const links = ["Home", "Tournament", "Pages", "Contact"];

const contact = { Contact: "/contact" };

const pages = (userId: string | null | undefined) => ({
  "About Us": "/about",
  Shop: "/shop",
  "Shop Details": "shop-details",
  Profile: userId ? `/profile/${userId}` : "/profile/id",
  Cart: "/cart",
  "Check Out": "/checkout",
  Features: "/features",
  Errors: "/error",
});

enum AuthState {
  IsLoading,
  Error,
  IsAuthenticated,
  NotAuthenticated,
}

function ResponsiveAppBar() {
  ///sd
  const [authState, setAuthState] = useState(AuthState.NotAuthenticated);
  const [userId, setUserId] = useState<string | null>(null);
  const theme = useTheme();
  const auth = useAuth();
  const location = useLocation();
  const userRed = useAppSelector((state) => state.user.value);
  const connection = useAppSelector((state) => state.connection.value);
  const dispatch = useDispatch();
  useEffect(() => {
    // Update the document title using the browser API
    if (auth.isLoading) {
      setAuthState(AuthState.IsLoading);
    } else if (auth.error) {
      setAuthState(AuthState.Error);
    } else if (auth.isAuthenticated) {
      joinHub();
      fetchUserId(auth.user?.id_token).then((id) => {
        setUserId(id);
        dispatch(updateId(id));
      });
      setAuthState(AuthState.IsAuthenticated);
    } else {
      exitHub();
      setAuthState(AuthState.NotAuthenticated);
    }
  }, [auth, auth.isAuthenticated]);

  const getToken = () => {
    return auth.user?.id_token;
  };

  const exitHub = async () => {
    if (connection) connection.stop();
  };
  const joinHub = async () => {
    try {
      if (!connection) {
        const conn = new HubConnectionBuilder()
          .withUrl(BASE_URL + "/hub", {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => {
              return `${getToken()}`;
            },
          })
          .configureLogging(LogLevel.Information)
          .build();
        dispatch(setConnection(conn));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const CloseHub = async () => {
    try {
      if (connection) {
        await connection.stop();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   auth.events.addUserLoaded(async (value) => {
  //     const user = await fetchData("user", value.id_token);
  //     console.log(value, user);
  //   });
  // }, [auth.events]);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const [anchorElPages, setAnchorElPages] = React.useState<null | HTMLElement>(
    null,
  );

  const [anchorElTournament, setAnchorElTournament] =
    React.useState<null | HTMLElement>(null);

  const handleOpenTournamentMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorElTournament != null) {
      setAnchorElTournament(null);
      return;
    }
    setAnchorElTournament(event.currentTarget);
  };

  const handleCloseTournamentMenu = () => {
    setAnchorElTournament(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenPagesMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorElPages != null) {
      setAnchorElPages(null);
      return;
    }
    setAnchorElPages(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClosePagesMenu = () => {
    setAnchorElPages(null);
  };

  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  const handleViewProfile = async () => {
    if (userId) {
      navigate(`/profile/${userId}`);
    }
  };

  return (
    <AppBar position="static" sx={{ p: 0 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 1, color: "white", display: "block" }}
            >
              <img src={Logo} alt="Logo" width={"200vw"}></img>
            </Button>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">{links[0]}</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">{links[1]}</Typography>
              </MenuItem>
              <MenuItem onClick={handleOpenPagesMenu}>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElPages}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElPages)}
                  onClose={handleClosePagesMenu}
                >
                  {Object.entries(pages(userId || "")).map(([page, value]) => (
                    <MenuItem
                      key={value}
                      onClick={() => {
                        handleClick(value);
                      }}
                    >
                      <Typography>{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
                <Typography textAlign="center">{links[2]}</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">{links[3]}</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {links[0]}
              </Button>
            </Link>
            <Link to="/tournament" style={{ textDecoration: "none" }}>
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                Tournament
              </Button>
            </Link>
            <Button
              onClick={handleOpenPagesMenu}
              sx={{
                my: 1,
                color: location.pathname.includes("/pages")
                  ? theme.palette.primary.main
                  : "white",
                display: "block",
                fontSize: "18px",
                mx: 1,
                fontFamily: "Orbitron, Arial, sans-serif",
              }}
            >
              {links[2]}
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElPages}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElPages)}
                onClose={handleClosePagesMenu}
              >
                {Object.entries(pages).map(([key, value]) => (
                  <MenuItem
                    key={value}
                    onClick={() => {
                      handleClick(value);
                    }}
                  >
                    <Typography>{key}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Button>
            <Button
              onClick={() => handleClick(contact["Contact"])}
              sx={{
                my: 1,
                color:
                  location.pathname === "/contact"
                    ? theme.palette.primary.dark
                    : "white",
                display: "block",
                fontSize: "18px",
                mx: 1,
                fontFamily: "Orbitron, Arial, sans-serif",
              }}
            >
              {links[3]}
            </Button>
          </Box>
          <Box>
            {authState == AuthState.NotAuthenticated ||
            authState == AuthState.Error ? (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.text.secondary,
                  py: { xs: "6px", md: "6px" },
                  px: { xs: "6px", md: "16px" },
                  width: 150,
                  fontFamily: "Orbitron, Arial, sans-serif",
                }}
                onClick={() => {
                  void auth.signinRedirect();
                }}
              >
                LOGIN
              </Button>
            ) : (
              <>
                <Tooltip title={"Open settings"}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src={auth.user?.profile.picture} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleViewProfile}>
                    <Typography textAlign="center">View Profile</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      void auth.removeUser();

                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign="center">Log Out</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
