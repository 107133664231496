import React from "react";

interface DateProp {
  startDate: string;
}

const FormatDate: React.FC<DateProp> = ({ startDate }) => {
  const formattedDate = new Date(startDate).toLocaleDateString("en-US", {
    timeZone: "America/New_York",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
  });

  return (
    <div>
      <span>{formattedDate}</span>
    </div>
  );
};

export default FormatDate;
