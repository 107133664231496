import "../App.css";

import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import support from '../images/features-icon-1.png';
import deposit from '../images/features-icon-2.png';
import rank from '../images/features-icon-3.png';
import money from '../images/features-icon-4.png';
import controller from '../images/features-icon-5.png';
import rich from '../images/features-icon-6.png';
import { useTheme } from "@mui/material";

interface BoxContent {
  image: string;
  header: string;
  body: string;
  bgcolor: string;
}

const boxes: BoxContent[] = [
  {
    image: support,
    header: 'PREMIUM SUPPORT',
    body: 'Our dedicated admins are there to answer in no time, avg response time is 5mins.',
    bgcolor:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgb(34, 40, 49))',
  },
  {
    image: deposit,
    header: 'INSTANT DEPOSITS',
    body: 'Make a deposit and receive your funds instantly to your account.',
    bgcolor:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgb(34, 40, 49))',
  },
  {
    image: rank,
    header: 'CLIMB THE LEADERBOARDS',
    body: 'Compete in monthly leaderboard challenges for real cash and prizes.',
    bgcolor:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgb(34, 40, 49))',
  },
  {
    image: money,
    header: 'MAKE 2X YOUR $$',
    body: 'Our dedicated admins are there to answer in no time, avg response time is 5mins.',
    bgcolor:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgb(34, 40, 49))',
  },
  {
    image: rich,
    header: 'MAKE UP TO 10X YOUR $$',
    body: 'Make up to 10X your money on multiplayer tourneys. With paid and free entry.',
    bgcolor:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgb(34, 40, 49))',
  },
  {
    image: controller,
    header: 'PLAY AT YOUR LEVEL',
    body: 'With ranked divisions we help you find the right level to compete.',
    bgcolor:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgb(34, 40, 49))',
  },
];

const FeatureComponent: React.FC = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        overflow: 'hidden',
        borderRadius: '1em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="h1"
        align="center"
        sx={{
          color: theme.palette.primary.dark,
          fontFamily: 'Orbitron, Arial, sans-serif',
          mb: 2
        }}
      >
        ESPORTIUM FEATURES
      </Typography>
      <Typography
        variant="h3"
        align="center"
        sx={{
          color: theme.palette.text.secondary,
          fontFamily: 'Orbitron, Arial, sans-serif',
          mb: 5
        }}
      >
        The biggest esports tournaments anytime, anywhere
      </Typography>
      <Grid container justifyContent="space-around" spacing={{ xs: 1, md: 1 }} columns={{ xs: 2, sm: 8, md: 12 }}>
        {boxes.map((box, index) => (
          <Grid item xs={2} sm={4} md={4} key={index} sx={{display: 'flex', justifyContent: 'center' }}>
            <Card
              sx={{
                m: '1em',
                width: "19rem",
                height: "19rem",
                borderRadius: '0.4em',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: box.bgcolor,
              }}
            >
              <Box sx={{
                width: '7.5rem', 
                height: '7.5rem', 
                borderRadius: '50%',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '0.4em',
                marginTop: '1.3em',
                bgcolor: theme.palette.primary.light, 
                border: '0.6rem solid ',
                borderColor: theme.palette.primary.dark
              }}>
                <CardMedia
                  component="img"
                  alt={box.header}
                  sx={{
                    objectFit: 'cover', 
                    width: '65%', 
                    height: '65%', 
                  }}
                  image={box.image}
                />
              </Box>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  align="center"
                  sx={{
                    marginBottom: '0.4em',
                    fontSize: '1.3em',
                    color: theme.palette.text.secondary, 
                  }}
                >
                  {box.header}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{
                    marginBottom: '0.1em',
                    fontSize: '0.9em',
                    color: theme.palette.text.secondary, 
                  }}
                >
                  {box.body}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeatureComponent;
