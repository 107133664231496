import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Box from "@mui/material/Box";
import { styled, ThemeProvider, useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { WagerType } from "../pages/Wager";
import Dialog from "@mui/material/Dialog";
import WagerPopup from "./WagerPopup";
import { useAppSelector } from "../hooks";

const WagerCard: React.FC<{
  wager: WagerType;
}> = ({ wager }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(false);

  const [timeLeft, setTimeLeft] = useState<string>("calculating..."); // State for formatted time
  const userRed = useAppSelector((state) => state.user.value);
  useEffect(() => {
    // Function to format the time in min:seconds
    const formatTime = (milliseconds: number): string => {
      const minutes = Math.floor(milliseconds / 60000); // Full minutes
      const seconds = Math.floor((milliseconds % 60000) / 1000); // Remaining seconds
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };

    // Calculate the initial time difference in milliseconds
    let timeLeftMs = new Date(wager.remainingTimeToJoin).getTime() - Date.now();

    // Update the time every second
    const intervalId = setInterval(() => {
      if (timeLeftMs <= 0) {
        clearInterval(intervalId); // Clear the interval when time is up
        setTimeLeft("00:00");
      } else {
        timeLeftMs -= 1000; // Reduce 1 second
        setTimeLeft(formatTime(timeLeftMs)); // Update state with the formatted time
      }
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [wager.remainingTimeToJoin]);

  return (
    <>
      <CardActionArea onClick={() => setOpen(true)}>
        <Card
          sx={{
            height: isSmallScreen ? 100 : isMediumScreen ? 150 : 200,
            width: isSmallScreen ? 100 : isMediumScreen ? 150 : 200,
            m: 1,

            transition: "transform 0.3s",
            "&:hover": { transform: "scale(1.1)" },
            bgcolor:
              userRed.userId.toString() === wager.creatorId
                ? "rgba(3, 152, 136,.6)"
                : "rgba(128, 128, 128, 0.6)", // grey color with 0.6 opacity
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Typography
              variant={isSmallScreen ? "h6" : isMediumScreen ? "h1" : "h1"}
              component="div"
              align="center"
              sx={{ fontWeight: 500 }}
            >
              ${wager.prizePool}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                align="left"
                sx={{
                  fontSize: isSmallScreen
                    ? "0.8em"
                    : isMediumScreen
                      ? "0.9em"
                      : "1em",
                }}
              >
                1V1
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                align="right"
                sx={{
                  fontSize: isSmallScreen
                    ? "0.8em"
                    : isMediumScreen
                      ? "0.9em"
                      : "1em",
                }}
              >
                {wager.creator}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{
                fontSize: isSmallScreen
                  ? "0.8em"
                  : isMediumScreen
                    ? "0.9em"
                    : "1em",
              }}
            >
              {timeLeft}
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
            padding: 0,
            borderRadius: 1,
          },
        }}
      >
        <WagerPopup onClose={() => setOpen(false)} wager={wager} />
      </Dialog>
    </>
  );
};

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  overflowY: "hidden",
  scrollbarWidth: "thin", // For Firefox
  scrollbarColor: "#888 #f1f1f1", // For Firefox
  "&::-webkit-scrollbar": {
    height: "8px", // For WebKit browsers
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1", // For WebKit browsers
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888", // For WebKit browsers
    borderRadius: "4px", // For WebKit browsers, adjust this to make corners round
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555", // For WebKit browsers
  },
});

const WagerBar = ({ wagers }: { wagers: WagerType[] }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const displayCount = isSmallScreen ? 2 : isMediumScreen ? 3 : 4; // Number of cards to display at once
  const [start, setStart] = React.useState(0);

  // console.log(wagers);
  const handlePrev = () => {
    setStart(Math.max(0, start - displayCount));
  };

  const handleNext = () => {
    setStart(Math.min(wagers.length - displayCount, start + displayCount));
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <AppBar position="static" color="transparent" elevation={0}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={handlePrev} disabled={start === 0}>
              <NavigateBeforeIcon />
            </IconButton>
            <Box sx={{ display: "flex", overflow: "hidden" }}>
              {wagers.map((wager, index) => (
                <>
                  <WagerCard key={index} wager={wager} />
                </>
              ))}
            </Box>
            <IconButton
              onClick={handleNext}
              disabled={start + displayCount >= wagers.length}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
        </AppBar>
      </>
    </ThemeProvider>
  );
};

export default WagerBar;
