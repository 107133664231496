import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";

interface Tournament {
  id: string;
  name: string;
  imageUrl: string;
}

interface ActiveTourneyProps {
  tournaments: Tournament[];
}

const TournamentCard: React.FC<{ tournament: Tournament }> = ({
  tournament,
}) => {
  const navigate = useNavigate();
  const { id, name, imageUrl } = tournament;
  const theme = useTheme();

  const handleClick = () => {
    navigate(`/tournamentsingle/${id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card
      sx={{
        height: 250,
        width: 200,
        m: 1,
        transition: "transform 0.3s",
        "&:hover": { transform: "scale(1.1)" },
        overflow: "visible",
      }}
    >
      <CardActionArea onClick={handleClick}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <img
            src={imageUrl}
            alt={name}
            style={{ width: "100%", height: "auto" }}
          />
          <Typography variant="body2" color="text.secondary" align="center">
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  overflowY: "hidden",
  scrollbarWidth: "thin",
  scrollbarColor: "#888 #f1f1f1",
  "&::-webkit-scrollbar": {
    height: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  width: "100%",
  height: 250,
  overflow: "visible",
});

const ActiveTourney: React.FC<ActiveTourneyProps> = ({ tournaments }) => {
  const theme = useTheme();

  return (
    <Box mt="2em">
      <Typography variant="h1" color={"wheat"}>
        Active Tournaments
      </Typography>
      <AppBar position="static" color="transparent" elevation={0}>
        <StyledBox
          sx={{
            mb: "3em",
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            overflowY: "hidden",
            width: "100%",
            height: 250,
            overflow: "visible",
          }}
        >
          {tournaments.map((tournament, index) => (
            <TournamentCard key={index} tournament={tournament} />
          ))}
        </StyledBox>
      </AppBar>
    </Box>
  );
};

export default ActiveTourney;
